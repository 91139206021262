import { configure, extend } from 'vee-validate'
import { required, email, max_value, min_value } from 'vee-validate/dist/rules'
import api from './api'

/*
 * Configure Vee Validate
 */
const config = {
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  },
  bails: false,
  skipOptional: true,
  mode: 'aggressive',
  useConstraintAttrs: true
}
configure(config)

/*
 * Import the validators
 */
extend('required', {
  ...required,
  message: 'The {_field_} field is required'
})
extend('email', {
  ...email,
  message: 'A valid email address is required'
})
extend('max_value', max_value)
extend('min_value', min_value)

extend('api_validate_email', {
  async validate(email, { table, id }) {
    if(!email || email.length < 4) {
      return false
    }
    try {
      const { data } = await api.emailValidate({ table, email, id })
      return data.valid
    } catch(e) {
      return false
    }
  },
  params: ['table', 'id'],
  message: 'This email address is not available.'
})

extend('validate_username', {
  getMessage: 'That username is already taken. Please choose another.',
  validate (value) {
    return new Promise(resolve => {
      api.usersValidate({ username: value }).then((response) => {
        resolve({
          valid: response.data.valid === true,
          data: response.data.valid === true ? { message: 'Valid' } : { message: 'Invalid' }
        })
      })
    })
  }
})