<template>
    <div class="card" v-if="user != null">
        <h3 class="card-title" v-if="user != null">Edit <strong>{{ user.full_name }}</strong></h3>
        <div class="row">
            <div class="col-lg-6">
                <validation-observer ref="observer" tag="form" @submit.prevent="validateBeforeSubmit" v-slot="{ invalid }">
                    <fieldset>
                        <div class="form-group">
                            <label for="username">Username</label>
                            <input :value="user.username" id="username" type="text" class="form-control" name="username" readonly>
                        </div>
                        <PSTextValidate id="first_name" label="First Name" :value="user.first_name" @input="updateValue('first_name', $event)" placeholder="First Name"></PSTextValidate>
                        <PSTextValidate id="last_name" label="Last Name" :value="user.last_name" @input="updateValue('last_name', $event)" placeholder="Last Name"></PSTextValidate>
                        <PSTextValidate id="email" label="Email" :value="user.email" @input="updateValue('email', $event)" placeholder="Email"></PSTextValidate>
                        <PSTextValidate id="phone" label="Phone" :value="user.phone" @input="updateValue('phone', $event)" placeholder="Phone"></PSTextValidate>
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input v-model="user.password" id="password" type="password" placeholder="Password" name="password" class="form-control">
                        </div>
                        <div class="form-group" v-if="typesStatusesLoaded">
                            <label for="type">User Type</label>
                            <select id="type" :value="user.type" @change="updateChange('type', $event)" class="form-control" name="type">
                                <option v-for="type in types" :key="type.id" :value="type.id">{{ type.name }}</option>
                            </select>
                            <div class="help-block">Please be careful when changing user types.</div>
                        </div>
                        <PSSelect v-if="typesStatusesLoaded" id="status" label="Status" :data="statuses" :value="user.status" @change="updateChange('status', $event)" :nullValue="false" :showValidation="false"></PSSelect>
                        <div class="form-group">
                            <button type="submit" class="btn btn-outline-primary" :disabled="invalid">Save</button>
                            <button class="btn btn-outline-danger" @click.prevent="cancel">Cancel</button>
                        </div>
                    </fieldset>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
  import api from './../../api'
  import {
    USERADD,
    USERUPDATE
  } from './../../store/mutation-types'
  import { mapState } from 'vuex'
  import PSText from '../../modules/form/PSText.vue'
  import PSTextValidate from '../../modules/form/PSTextValidate.vue'
  import PSSelect from '../../modules/form/PSSelect.vue'
  import { ValidationObserver } from 'vee-validate'

  export default {
    props: {
      id: {
        required: true
      }
    },
    components: {
      PSText,
      PSTextValidate,
      PSSelect,
      ValidationObserver
    },
    computed: {
      user: {
        get () {
          return this.$store.state.users.user
        },
        set (data) {
          this.$store.commit(USERUPDATE, data)
        }
      },
      ...mapState({
        types: state => state.typesStatuses.userTypes,
        statuses: state => state.typesStatuses.status,
        typesStatusesLoaded: state => state.typesStatuses.loaded
      })
    },
    mounted () {
      if (this.user == null) {
        api.usersGet(this.id)
      }
    },
    methods: {
      updateValue (name, e) {
        this.user = {
          id: name,
          value: e
        }
      },
      updateChange (name, e) {
        this.user = {
          id: name,
          value: e.target.value
        }
      },
      validateBeforeSubmit (next) {
        this.$refs.observer.validate().then(success => {
          if (!success) {
            return
          }

          api.usersUpdate()
          this.$router.push({ name: 'users' })
        }).catch(error => {
          console.log('validation fails', error)
          // console.log('validation fails', this.fields)
        })
      },
      cancel () {
        this.$store.commit(USERADD, null)
        this.$router.push({ name: 'users' })
      }
    }
  }
</script>
