/* ============
 * Campaigns Module
 * ============
 */

import state from './state';
import mutations from './mutations';

export default {
  state,
  mutations,
};
