<template>
    <div class="card">
        <h3 class="card-title" v-if="user !== null">Edit <strong>{{ user.name }}</strong></h3>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-6">
                    <validation-observer ref="observer" tag="form" @submit.prevent="validateBeforeSubmit" autocomplete="off" v-slot="{ invalid }">
                        <fieldset>
                            <PSTextValidate id="username" label="Username" v-model="user.username" validate="validate_username|required" placeholder="Username"></PSTextValidate>
                            <PSTextValidate id="email" label="Email" v-model="user.email" placeholder="Email"></PSTextValidate>
                            <PSTextValidate id="first_name" label="First Name" v-model="user.first_name" placeholder="First Name"></PSTextValidate>
                            <PSTextValidate id="last_name" label="Last Name" v-model="user.last_name" placeholder="Last Name"></PSTextValidate>
                            <PSTextValidate id="phone" label="Phone" v-model="user.phone" placeholder="Phone"></PSTextValidate>
                            <validation-provider tag="div" class="form-group" name="password" rules="required" v-slot="{ errors, classes }">
                                <label for="password">Password</label>
                                <input v-model="user.password" id="password" type="password" placeholder="Password" name="password" class="form-control" :class="classes">
                                <transition name="fade-grow" mode="out-in">
                                    <div class="invalid-feedback" v-if="errors">{{ errors[0] }}</div>
                                </transition>
                            </validation-provider>
                            <PSSelect v-if="typesStatusesLoaded" id="type" label="Type" :data="types" :value="user.type" @change="updateUser('type', $event)" :nullValue="false" :showValidation="false"></PSSelect>
                            <PSSelect v-if="typesStatusesLoaded" id="status" label="Status" :data="statuses" :value="user.status" @change="updateUser('status', $event)" :nullValue="false" :showValidation="false"></PSSelect>
                            <div class="form-group">
                                <button type="submit" class="btn btn-outline-primary" :disabled="invalid">Save</button>
                                <button class="btn btn-outline-danger" @click.prevent="cancel">Cancel</button>
                            </div>
                        </fieldset>
                    </validation-observer>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import api from './../../api'
  import {
    USERADD
  } from './../../store/mutation-types'
  import { mapState } from 'vuex'
  import PSTextValidate from '../../modules/form/PSTextValidate.vue'
  import PSSelect from '../../modules/form/PSSelect.vue'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'

  export default {
    data () {
      return {
        user: {
          username: null,
          email: null,
          first_name: null,
          last_name: null,
          phone: null,
          type: 0,
          status: 1,
          password: ''
        }
      }
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      PSTextValidate,
      PSSelect
    },
    computed: mapState({
      types: state => state.typesStatuses.userTypes,
      statuses: state => state.typesStatuses.status,
      typesStatusesLoaded: state => state.typesStatuses.loaded
    }),
    methods: {
      updateUser (field, e) {
        this.user[field] = e.target.value
      },
      validateBeforeSubmit () {
        this.$refs.observer.validate().then(success => {
          if (!success) {
            return
          }

          api.usersAdd(this.user)
          this.$router.push({ name: 'users' })
        }).catch(error => {
          console.log('validation fails', error)
        })
      },
      cancel () {
        this.$store.commit(USERADD, null)
        this.$router.push({ name: 'users' })
      }
    }
  }
</script>
