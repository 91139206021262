import {
    USERADD,
    USERSADD,
	  PMUSERSADD,
    USERUPDATE,
    USERDELETE,
    USERSUPDATE,
    WEEKCURRENT,
    WEEKS,
    TIMESHEETUSERS
} from './../../mutation-types';

export default {
    [USERADD](state, data) {
        state.user = data
    },
    [USERSADD](state, data) {
        state.users = data
    },
    [PMUSERSADD](state, data) {
        state.projectManagers = data
    },
    [USERUPDATE](state, data) {
        state.user[data.id] = data.value
    },
    [USERDELETE](state, id) {
        state.users = state.users.filter(user => user.id !== id)
    },
    [USERSUPDATE](state, user) {
        let index = state.users.findIndex(temp => temp.id === user.id)
        state.users.splice(index, 1, user)
    },
    [WEEKCURRENT](state, data) {
        state.weekCurrent = data
    },
    [WEEKS](state, data) {
        state.weeks = data
    },
    [TIMESHEETUSERS](state, data) {
        state.timesheetUsers = data
    },
};
