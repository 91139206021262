<template>
  <vue-dropzone
      ref="dropzone"
      id="dropzone"
      :options="options"
      @vdropzone-success="success"
      @vdropzone-error="displayError">
  </vue-dropzone>
</template>
<script>
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  export default {
    props: {
      id: {
        type: String,
        required: true
      },
      url: {
        type: String,
        required: true
      },
      clickable: {
        type: Boolean,
        default: true
      },
      paramName: {
        type: String,
        default: 'file'
      },
      acceptedFileTypes: {
        type: String
      },
      thumbnailHeight: {
        type: Number,
        default: 100
      },
      thumbnailWidth: {
        type: Number,
        default: 100
      },
      showRemoveLink: {
        type: Boolean,
        default: true
      },
      maxFileSizeInMB: {
        type: Number,
        default: 20
      },
      maxNumberOfFiles: {
        type: Number,
        default: 50
      },
      autoProcessQueue: {
        type: Boolean,
        default: true
      },
      useFontAwesome: {
        type: Boolean,
        default: false
      },
      headers: {
        type: Object
      },
      language: {
        type: Object,
        default: function() {
          return {
            dictDefaultMessage: '<br><span class="dz-text">Drop files here to upload</span>',
            dictCancelUpload: 'Cancel upload',
            dictCancelUploadConfirmation: 'Are you sure you want to cancel this upload?',
            dictFallbackMessage: 'Your browser does not support drag and drop file uploads.',
            dictFallbackText: 'Please use the fallback form below to upload your files like in the olden days.',
            dictFileTooBig: 'File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.',
            dictInvalidFileType: `You can't upload files of this type.`,
            dictMaxFilesExceeded: 'You can not upload any more files. (max: {{maxFiles}})',
            dictRemoveFile: 'Remove',
            dictRemoveFileConfirmation: null,
            dictResponseError: 'Server responded with {{statusCode}} code.',
          }
        }
      },
      useCustomDropzoneOptions: {
        type: Boolean,
        default: false
      },
      dropzoneOptions: {
        type: Object
      }
    },
    components: {
      vueDropzone: vue2Dropzone
    },
    computed: {
      cloudIcon: function () {
        return  '<i class="zmdi zmdi-cloud-upload"></i>'
      },
      doneIcon: function () {
        return  ' <i class="zmdi zmdi-cloud-done"></i>'
      },
      errorIcon: function() {
        return  ' <i class="zmdi zmdi-cloud-off"></i>'
      }
    },
    data () {
      return {
        options: this.$refs.dropzoneOptions
      }
    },
    created () {
      if (this.$isServer) {
        return
      }
      if (!this.useCustomDropzoneOptions) {
        this.options = {
          clickable: this.clickable,
          paramName: this.paramName,
          thumbnailWidth: this.thumbnailWidth,
          thumbnailHeight: this.thumbnailHeight,
          maxFiles: this.maxNumberOfFiles,
          maxFilesize: this.maxFileSizeInMB,
          addRemoveLinks: this.showRemoveLink,
          acceptedFiles: this.acceptedFileTypes,
          autoProcessQueue: this.autoProcessQueue,
          headers: this.headers,
          timeout: 600000,
          // style="width:' + this.thumbnailWidth + 'px;height:' + this.thumbnailHeight + 'px"
          previewTemplate: '<div class="dz-preview dz-file-preview">  <div class="dz-image"><img data-dz-thumbnail /></div><div class="dz-details"><div class="dz-size"><span data-dz-size></span></div><div class="dz-filename"><span data-dz-name></span></div></div><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div><div class="dz-error-message"><span data-dz-errormessage></span></div><div class="dz-success-mark">' + this.doneIcon + ' </div><div class="dz-error-mark">' + this.errorIcon + '</div></div>',
          dictDefaultMessage: '<button class="dz-button">' + this.cloudIcon + this.language.dictDefaultMessage + '</button>',
          dictCancelUpload: this.language.dictCancelUpload,
          dictCancelUploadConfirmation: this.language.dictCancelUploadConfirmation,
          dictFallbackMessage: this.language.dictFallbackMessage,
          dictFallbackText: this.language.dictFallbackText,
          dictFileTooBig: this.language.dictFileTooBig,
          dictInvalidFileType: this.language.dictInvalidFileType,
          dictMaxFilesExceeded: this.language.dictMaxFilesExceeded,
          dictRemoveFile: this.language.dictRemoveFile,
          dictRemoveFileConfirmation: this.language.dictRemoveFileConfirmation,
          dictResponseError: this.language.dictResponseError
        }
      }
      this.options.url = this.url
    },
    methods: {
      success (file, response) {
        this.$emit('vdropzone-success', file, response, this.$refs.dropzone)
      },
      displayError (file, error, xhr) {
        this.$emit('vdropzone-error', file, error, xhr)
      }
    }
  }
</script>