export default {
    loaded: false,
    userTypes: {},
    userTypesNames: {},
    status: {},
    statusNames: {},
    jobStatus: {},
    jobStatusNames: {},
    fileTypes: {},
    fileTypesNames: {},
    fileSections: {},
    fileSectionsNames: {},
    fieldUserLogTypes: {},
    fieldUserLogTypesNames: {}
};
