import './bootstrap'

import Vue from 'vue'
import router from './router'
import store from './store'
import './validation'
import { mapState } from 'vuex'

import { Vue2Dragula } from 'vue2-dragula'

import MainNav from './components/MainNav.vue'
import SiteHeader from './components/SiteHeader.vue'
import Loader from './components/Loader.vue'

import moment from 'moment'
import accounting from 'accounting'

//scss
import '../sass/app.scss'

/**
 * Vue 2 Dragula
 */
Vue.use(Vue2Dragula);

/**
 * Filters
 */
Vue.filter('currencyFormatted', function (value) {
    return accounting.formatMoney(value, '$', 2, ' ', '.')
});
Vue.filter('dateFormatted', function (value) {
    return moment(value).format('D/MM/YYYY')
});
Vue.filter('dateTimeFormatted', function (value) {
    return moment(value).format('D/MM/YYYY HH:mm')
});

/**
 * Bootstrap Vue components and directives
 */
import { NavbarPlugin } from 'bootstrap-vue';
Vue.use(NavbarPlugin);

/**
 * Initialise Vue
 */
new Vue({
    router,
    store,
    computed: {
        ...mapState({
            authenticated: state => state.auth.authenticated
        })
    },
    mounted() {
        $('html').addClass('vue-active')
    },
    components: {
        MainNav,
        SiteHeader,
        Loader
    }
}).$mount('#app');

