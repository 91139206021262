import {
  VEHICLEADD,
  VEHICLESADD,
  VEHICLEUPDATE,
  VEHICLEDELETE
} from './../../mutation-types'

export default {
    [VEHICLESADD](state, data) {
        state.vehicles = data
    },
    [VEHICLEADD](state, data) {
        if(data == null) {
            data = {
                name: null,
                registration: null,
                status: 1
            }
            state.vehicleSelected = false
        } else {
            state.vehicleSelected = true
        }
        state.vehicle = data
    },
    [VEHICLEUPDATE](state, data) {
        state.vehicle[data.id] = data.value
    },
  [VEHICLEDELETE](state, id) {
    state.vehicles = state.vehicles.filter(vehicle => vehicle.id !== id)
  },
};
