import {
    LOADERINCREMENT,
    LOADERDECREMENT,
    LOADERSETMESSAGE,
    LOADERSHOWERROR
} from './../../mutation-types';

export default {
    [LOADERINCREMENT](state) {
        state.loading++
    },
    [LOADERDECREMENT](state) {
        if(state.loading > 0) {
            state.loading--
        }
    },
    [LOADERSETMESSAGE](state, message) {
        state.message = message
    },
    [LOADERSHOWERROR](state) {
        state.error = true
        state.loading++
    }
};
