<template>
    <validation-provider tag="div" class="form-group" :name="label" :rules="validate" v-slot="{ errors, classes }">
        <label :for="id">{{ label }}</label>
        <select :id="id" :value="value" @change="updateChange" @input="updateValue" :name="id" class="form-control" :class="classes">
            <option :value="null" v-if="nullValue">Please Select</option>
            <option v-for="item in data" :value="item.id" :key="item.id">{{ item.name }}</option>
        </select>
        <div class="invalid-feedback" v-if="errors">{{ errors[0] }}</div>
    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },
  props: {
    label: {},
    id: {},
    value: {},
    data: {},
    nullValue: {
      type: Boolean,
      default: false
    },
    showValidation: {
      type: Boolean,
      default: true
    },
    validate: {
      type: String,
      default: 'required'
    }
  },
  methods: {
    updateValue: function (e) {
      this.$emit('input', e)
    },
    updateChange: function (e) {
      this.$emit('change', e)
    }
  }
}
</script>
