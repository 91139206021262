<template>
    <div class="form-group">
        <label :for="id" v-if="label.length > 0">{{ label }}</label>
        <multiselect
                :id="id"
                :options="loadOptions"
                :value="value"
                :multiple="multi"
                :searchable="true"
                :close-on-select="true"
                :loading="isLoading"
                placeholder="Type to search"
                :label="search_by"
                :max="max"
                key="id"
                track-by="id"
                @search-change="asyncFind"
                @input="updateValue"
                v-on:enterKeyPressedNoResults="enterKeyPressedNoResults"
        >
        </multiselect>
    </div>
</template>

<script>
  import { debounce } from 'lodash'
  import api from './../../api'

  //LIBRARY: https://github.com/monterail/vue-multiselect has been absorbed into project
  import Multiselect from './multiselect/Multiselect.vue'

  export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        required: true
      },
      multi: {
        type: Boolean,
        required: true
      },
      preload: {
        type: Boolean,
        default: false
      },
      value: {},
      filter_by: {
        type: Object,
        required: false
      },
      search_by: {
        type: String,
        default: 'name'
      },
      max: {
        type: Number
      },
      options: {
        type: Array,
        default () {
          return []
        }
      },
      serverSideSearch: {
        type: Boolean,
        default: false
      }
    },
    // OR register locally
    components: { Multiselect },
    data () {
      return {
        loadOptions: [],
        isLoading: false,
        query_value: ''
      }
    },
    methods: {
      updateValue (value, id) {
        this.$emit('input', value)
        this.$emit('optionSelected', value)
      },
      asyncFind: debounce(
        function (query) {
          if (!this.serverSideSearch || !query) {
            return false
          }

          const data = {
            type: this.id,
            query: query,
            filter_by: this.filter_by
          }

          this.isLoading = true
          api.typeAhead(data).then(response => {
            this.loadOptions = response.data
            this.isLoading = false
          })
          this.query_value = query
        },
        500
      ),
      enterKeyPressedNoResults () {
        this.$emit('enterKeyPressedNoResults', [this.query_value])
      },
      preload_options () {
        const data = {
          type: this.id,
          filter_by: this.filter_by
        }
        if (this.preload) {
          this.isLoading = true
          api.preload(data).then(response => {
            this.loadOptions = response.data
            this.isLoading = false
          })
        } else {
          this.loadOptions = this.options
          this.isLoading = false
        }
      }
    },
    mounted () {
      this.preload_options()
    },
    watch: {
      filter_by: function (val) {
        this.preload_options()
      }
    }
  }
</script>
