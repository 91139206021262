<template>
    <div class="card">
        <PSTypeAhead id="weekEnding" label="Week Ending" :preload="true" :multi="false" :value="weekCurrent" @input="updateWeekCurrent"></PSTypeAhead>
        <table v-if="users != null && users.length > 0 && typesStatusesLoaded" class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id" v-if="user.type <= userTypesNames.employee">
                    <td>{{ user.full_name }}</td>
                    <td>{{ user.phone }}</td>
                    <td><a :href="mailTo(user)">{{ user.email }}</a></td>
                    <td>{{ userTypes[user.type].name }}</td>
                    <td class="table-actions actions-lg">
                        <div>
                            <button class="btn btn-zmdi-icon" @click="view(user)"><i class="zmdi mr-1 zmdi-alarm-check text-success"></i></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 v-else-if="weekCurrent == null" class="text-center">Please select a week.</h3>
        <h3 v-else class="text-center">There are no logs that can be used for time or run sheets for this week.</h3>
    </div>
</template>

<script>
    import api from '../../api'
    import {
        mapState
    } from 'vuex'
    import store from '../../store'
    import {
        USERADD,
        WEEKCURRENT
    } from '../../store/mutation-types'
    import PSTypeAhead from '../../modules/form/PSTypeAhead.vue'

    export default {
        name: 'timesheetsList',
        components: {
            PSTypeAhead
        },
        computed: mapState({
            users: state => state.users.timesheetUsers,
            userTypes: state => state.typesStatuses.userTypes,
            userTypesNames: state => state.typesStatuses.userTypesNames,
            statuses: state => state.typesStatuses.status,
            typesStatusesLoaded: state => state.typesStatuses.loaded,
            weekCurrent: state => state.users.weekCurrent,
            isSuperUser: state => state.auth.user.type == state.typesStatuses.userTypes.administrator
        }),
        methods: {
            view(user) {
                if(this.weekCurrent == null) {
                    swal('Please select a week', 'Select the week that you want to view timesheets for.', 'warning')
                } else {
                    store.commit(USERADD, user)
                    this.$router.push({name: 'time-sheets-view', params: { user_id: user.id, date: this.weekCurrent.id }})
                }
            },
            mailTo(user) {
                return 'mailto:' + user.email
            },
            updateWeekCurrent(value) {
                store.commit(WEEKCURRENT, value)
                api.timesheetUsers(value.id)
            }
        }
    }
</script>
