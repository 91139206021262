<template>
    <div class="card">
        <vuetable-search-filter :filters="moreParams" @filter-set="onFilterSet" @search-set="onSearchSet" @search-reset="onSearchReset"></vuetable-search-filter>
        <vuetable ref="vuetable" :api-url="apiUrl" :loadOnStart="false" :http-options="httpOptions" :fields="fields" :css="css" :per-page="vueTableParams.per_page" pagination-path="pagination" :append-params="appendParams" @vuetable:pagination-data="onPaginationData" @vuetable:loaded="loaded" @setJobStatus="setJobStatus"></vuetable>
        <div class="vuetable-pagination d-flex justify-content-end align-items-end flex-column mt-2">
            <vuetable-pagination-info-bootstrap ref="paginationInfo"></vuetable-pagination-info-bootstrap>
            <vuetable-pagination-bootstrap ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination-bootstrap>
        </div>
    </div>
</template>
<script>
  import Vue from 'vue'

  import Actions from './JobListActions.vue'

  Vue.component('actions', Actions)

  import Statuses from './JobListStatuses.vue'

  Vue.component('statuses', Statuses)

  import Files from './JobListFiles.vue'

  Vue.component('files', Files)

  import Vuetable from 'vuetable-2/src/components/Vuetable.vue'
  import VuetablePaginationInfoBootstrap from './../vuetable-2/PaginationInfoBootstrap.vue'
  import VuetablePaginationBootstrap from './../vuetable-2/PaginationBootstrap.vue'
  import VuetableSearchFilter from './../vuetable-2/JobSearchFilter.vue'

  import {
    mapState
  } from 'vuex'
  import moment from 'moment'

  import axios from 'axios'

  export default {
    components: {
      Vuetable,
      VuetablePaginationInfoBootstrap,
      VuetablePaginationBootstrap,
      VuetableSearchFilter
    },
    data () {
      return {
        fields: [
          {
            name: 'start',
            sortField: 'start',
            callback (value) {
              return moment(value).format('D-MMM-YYYY')
            }
          },
          {
            name: 'cartel_job_number',
            sortField: 'cartel_job_number',
            title: 'Cartel Job Number'
          },
          {
            name: 'telstra_job_number',
            sortField: 'telstra_job_number',
            title: 'Telstra Job Number'
          },
          {
            name: 'project_manager',
            title: 'Project Manager',
            callback: 'showProjectManager'
          },
          {
            name: 'address',
            sortField: 'address',
            dataClass: 'job-table-address'
          },
          {
            name: '__component:statuses',
            title: 'Status',
            dataClass: 'job-table-status',
            sortField: 'status'
          },
          {
            name: '__component:files',
            title: 'Files Count',
            dataClass: 'job-table-files'
          },
          {
            name: '__component:actions',
            title: 'Actions',
            dataClass: 'job-table-actions'
          }
        ],
        css: {
          tableClass: 'table table-striped table-hover',
          loadingClass: 'loading',
          ascendingIcon: 'icon-chevron-up',
          descendingIcon: 'icon-chevron-down',
          detailRowClass: 'vuetable-detail-row',
          handleIcon: 'icon-handle',
          renderIcon (classes, options) {
            if (classes.indexOf('sort-icon') !== -1) {
              if (classes.indexOf('icon-chevron-up') !== -1) {
                return `<span class="${classes.join(' ')}" ${options}><svg width="16" height="16" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1395 1184q0 13-10 23l-50 50q-10 10-23 10t-23-10l-393-393-393 393q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l466 466q10 10 10 23z"/></svg></span>`
              } else {
                return `<span class="${classes.join(' ')}" ${options}><svg width="16" height="16" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg></span>`
              }
            }
            return `<span class="${classes.join(' ')}" ${options}></span>`
          },
        },
        moreParams: {
          status: null,
          project_manager: null,
          search: null
        },
        vueTableParams: {
          sort: "",
          page: 1,
          per_page: 20
        },
        appendParams: {},
        init: false
      }
    },
    computed: {
      apiUrl() {
        return axios.defaults.baseURL + 'jobs/vue-table'
      },
      ...mapState({
       httpOptions: (state) => {
         return {
           headers: {
             'X-CSRF-TOKEN': window.Cartel.csrfToken,
             'Authorization': 'Bearer ' + state.auth.token
           }
         }
       },
      statusNames: state => state.typesStatuses.jobStatusNames,
      statuses: state => state.typesStatuses.jobStatus,
      user: state => state.auth.user
      }),
    },
    created () {
      const jobListMoreParams = localStorage.getItem('jobListMoreParams')
      const jobListVuetableParams = localStorage.getItem('jobListVuetableParams')
      if (jobListMoreParams !== null) {
        this.moreParams = Vue.observable(JSON.parse(jobListMoreParams))
        this.vueTableParams = Vue.observable(JSON.parse(jobListVuetableParams))
        this.appendParams = { ...this.moreParams, ...this.vueTableParams }
      }
      console.log('created:', axios.defaults)
    },
    mounted () {
      this.$refs.vuetable.refresh()
    },
    methods: {
      showStatus (status) {
        if (status == null) {
          return ''
        }
        return this.statuses[status].name
      },
      filesCount () {
        return 'PDF Files:'
      },
      showProjectManager (project_manager) {
        if (project_manager == null) {
          return ''
        }
        return project_manager.full_name
      },
      setJobStatus (job) {
        this.$refs.vuetable.refresh()
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        console.log('paginationData set', paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
        this.$refs.pagination.setPaginationData(paginationData)
      },
      onFilterSet (filter) {
        this.$set(this.moreParams, 'status', filter.status)
        this.$set(this.moreParams, 'project_manager', filter.project_manager)
        this.appendParams = this.moreParams
        Vue.nextTick(() => this.$refs.vuetable.refresh())
      },
      onSearchSet (search) {
        this.$set(this.moreParams, 'search', search)
        this.appendParams = this.moreParams
        Vue.nextTick(() => this.$refs.vuetable.refresh())
      },
      onSearchReset () {
        this.moreParams = Vue.observable({
          status: null,
          project_manager: null,
          search: null
        })
        this.vueTableParams = Vue.observable({
          sort: '',
          page: 1,
          per_page: 20
        })
        this.appendParams = this.moreParams
        Vue.nextTick(() => this.$refs.vuetable.refresh())
      },
      loaded () {
        if (this.init) {
          localStorage.setItem('jobListMoreParams', JSON.stringify(this.moreParams))
          this.vueTableParams = this.$refs.vuetable.getAllQueryParams()
          localStorage.setItem('jobListVuetableParams', JSON.stringify(this.vueTableParams))
        } else {
          this.appendParams = this.moreParams
        }
        this.init = true
      }
    },
    watch: {
      'status' (status) {
        let params = {
          status: status
        }
        this.filterStatus = status
        if (this.pm === true) {
          params.pm = this.user.id
          if (this.currentStatusFilter !== null) {
            params.status = this.currentStatusFilter
            this.filterStatus = this.currentStatusFilter
          }
        }
        this.moreParams = {
          ...this.moreParams,
          ...params
        }
        Vue.nextTick(() => this.$refs.vuetable.refresh())
      }
    }

  }
</script>
