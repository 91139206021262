/* ============
 * Auth Module
 * ============
 */

import state from './state'
import mutations from './mutations'
import actions from './actions'

export default {
  state,
  mutations,
  actions,
};
