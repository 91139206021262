<template>
    <div class="card">
        <h3 class="card-title" v-if="vehicle != null">Edit <strong>{{ vehicle.name }}</strong></h3>
        <div class="card-body" v-if="vehicle != null">
            <validation-observer ref="observer" tag="form" @submit.prevent="validateBeforeSubmit" v-slot="{ invalid }">
                <fieldset>
                    <PSTextValidate id="name" label="Name" :value="vehicle.name" @input="updateValue('name', $event)" placeholder="Name"></PSTextValidate>
                    <PSTextValidate id="registration" label="Registration" :value="vehicle.registration" @input="updateValue('registration', $event)" placeholder="Registration"></PSTextValidate>
                    <PSSelect v-if="statusesLoaded" id="status" label="Status" :data="statuses" :value="vehicle.status" @change="updateChange('status', $event)" :nullValue="false" :showValidation="false"></PSSelect>
                    <div class="form-group">
                        <button type="submit" class="btn btn-outline-primary" :disabled="invalid">Save</button>
                        <button class="btn btn-outline-danger" @click.prevent="cancel">Cancel</button>
                    </div>
                </fieldset>
            </validation-observer>
        </div>
    </div>
</template>

<script>
  import api from './../../api'
  import {
    VEHICLEADD,
    VEHICLEUPDATE
  } from './../../store/mutation-types'
  import { mapState } from 'vuex'
  import PSTextValidate from '../../modules/form/PSTextValidate.vue'
  import PSSelect from '../../modules/form/PSSelect.vue'
  import { ValidationObserver } from 'vee-validate'

  export default {
    $_veeValidate: { validator: 'new' },
    props: {
      id: {
        required: true
      }
    },
    components: {
      PSTextValidate,
      PSSelect,
      ValidationObserver
    },
    computed: {
      vehicle: {
        get () {
          return this.$store.state.vehicles.vehicle
        },
        set (data) {
          this.$store.commit(VEHICLEUPDATE, data)
        }
      },
      ...mapState({
        statuses: state => state.typesStatuses.status,
        statusesLoaded: state => state.typesStatuses.loaded
      })
    },
    mounted () {
      if (!this.vehicleSelected) {
        api.vehiclesGet(this.id)
      }
    },
    methods: {
      updateValue (name, e) {
        this.vehicle = {
          id: name,
          value: e
        }
      },
      updateChange (name, e) {
        this.vehicle = {
          id: name,
          value: e.target.value
        }
      },
      validateBeforeSubmit (next) {
        this.$ref.observer.validate().then(success => {
          if (!success) {
            return
          }
          api.vehiclesUpdate()
          this.$router.push({ name: 'vehicles' })
        }).catch(error => {
          console.log('validation fails', error)
        })
      },
      cancel () {
        this.$store.commit(VEHICLEADD, null)
        this.$router.push({ name: 'vehicles' })
      }
    }
  }
</script>
