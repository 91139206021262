<template>
    <div class="card">
        <h3 class="card-title">Add a New Vehicle</h3>
        <div class="card-body">
            <validation-observer ref="observer" tag="form" @submit.prevent="validateBeforeSubmit" v-slot="{ invalid }">
                <fieldset>
                    <PSTextValidate id="name" label="Name" v-model="vehicle.name" placeholder="Name"></PSTextValidate>
                    <PSTextValidate id="registration" label="Registration" v-model="vehicle.registration" placeholder="Registration"></PSTextValidate>
                    <PSSelect v-if="statusesLoaded" id="status" label="Status" :data="statuses" :value="vehicle.status" @change="updateStatus" :nullValue="false" :showValidation="false"></PSSelect>
                    <div class="form-group">
                        <button type="submit" class="btn btn-outline-primary" :disabled="invalid">Save</button>
                        <button class="btn btn-outline-danger" @click.prevent="cancel">Cancel</button>
                    </div>
                </fieldset>
            </validation-observer>
        </div>
    </div>
</template>


<script>
  import api from './../../api'
  import {
    VEHICLEADD,
    VEHICLEUPDATE
  } from './../../store/mutation-types'
  import { mapState } from 'vuex'
  import PSTextValidate from '../../modules/form/PSTextValidate.vue'
  import PSSelect from '../../modules/form/PSSelect.vue'
  import { ValidationObserver } from 'vee-validate'

  export default {
    components: {
      PSTextValidate,
      PSSelect,
      ValidationObserver
    },
    data () {
      return {
        vehicle: {
          name: null,
          registration: null,
          status: 1
        }
      }
    },
    computed: mapState({
      statuses: state => state.typesStatuses.status,
      statusesLoaded: state => state.typesStatuses.loaded
    }),
    methods: {
      updateStatus (e) {
        this.vehicle.status = e.target.value
      },
      validateBeforeSubmit () {
        this.$refs.observer.validate().then(success => {
          if (!success) {
            return
          }
          api.vehiclesAdd(this.vehicle)
          this.$router.push({ name: 'vehicles' })
        }).catch(error => {
          console.log('validation fails', error)
        })
      },
      cancel () {
        this.$router.push({ name: 'vehicles' })
      }
    }
  }
</script>
