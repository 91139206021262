<template>
    <div>
        <div>
            <button class="btn btn-zmdi-icon" @click="setJobRedirect(rowData, 'jobs-edit')" title="Details">
                <i class="zmdi mr-1 zmdi-edit text-success"></i></button>
            <button class="btn btn-zmdi-icon" @click="setJobRedirect(rowData, 'jobs-files')" title="Files">
                <i class="zmdi mr-1 zmdi-collection-item text-success"></i></button>
            <button class="btn btn-zmdi-icon" @click="setJobRedirect(rowData, 'jobs-notes')" title="Notes">
                <i class="zmdi mr-1 zmdi-assignment text-success"></i></button>
        </div>
        <div v-if="rowData.status >= statusNames.started">
            <button class="btn btn-zmdi-icon" @click="setJobRedirect(rowData, 'jobs-field-report')" title="Field Report">
                <i class="zmdi mr-1 zmdi-collection-plus text-info"></i></button>
            <button class="btn btn-zmdi-icon" @click="completeJob(rowData.id)" title="Complete Job" v-if="rowData.status < statusNames.complete">
                <i class="zmdi mr-1 zmdi-check text-primary"></i></button>
        </div>
        <div>
            <button v-if="isAllowedUser" class="btn btn-zmdi-icon" @click="deleteJob(rowData.id)" title="Delete Job">
                <i class="zmdi mr-1 zmdi-delete text-danger"></i></button>
            <button v-if="isSuperUser" class="btn btn-zmdi-icon" @click="fullDeleteJob(rowData.id)" title="Full Delete Job">
                <i class="zmdi mr-1 zmdi-close-circle text-danger"></i></button>
            <button v-if="isSuperUser && rowData.deleted_at !== null" class="btn btn-zmdi-icon" @click="restoreJob(rowData.id)" title="Full Delete Job">
                <i class="zmdi mr-1 zmdi-refresh-alt text-primary"></i></button>
        </div>
    </div>
</template>
<script>
  import api from '../../api'
  import {
    mapState
  } from 'vuex'
  import store from '../../store'
  import { JOBADD } from '../../store/mutation-types'

  export default {
    props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    computed: mapState({
      statusNames: state => state.typesStatuses.jobStatusNames,
      statuses: state => state.typesStatuses.jobStatus,
      isSuperUser: state => state.auth.user.type === state.typesStatuses.userTypesNames.administrator,
      isAllowedUser: state => state.auth.user.type === state.typesStatuses.userTypesNames.administrator || state.auth.user.type === state.typesStatuses.userTypesNames.office
    }),
    methods: {
      setJobRedirect (job, redirect) {
        this.$router.push({ name: redirect, params: { id: job.id } })
      },
      deleteJob (job_id) {
        swal({
          title: 'Are you sure you want to delete this Job?',
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Delete Job',
              value: true,
              className: 'btn-danger'
            },
            cancel: {
              text: 'Cancel',
              value: false,
              className: 'btn-success',
              visible: true
            }
          }
        }).then((isConfirm) => {
          if (isConfirm) {
            api.jobsDelete(job_id).then((response) => {
              api.jobGet()
            })
          }
        })
      },
      fullDeleteJob (job_id) {
        swal({
          title: 'Are you sure you want to fully delete this Job and all the associated files and data?',
          text: 'This cannot be undone',
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Full Delete Job',
              value: true,
              className: 'btn-danger'
            },
            cancel: {
              text: 'Cancel',
              value: false,
              className: 'btn-success',
              visible: true
            }
          }
        }).then((isConfirm) => {
          if (isConfirm) {
            api.jobsFullDelete(job_id).then((response) => {
              this.$parent.reload()
            })
          }
        })
      },
      restoreJob (job_id) {
        swal({
          title: 'Are you sure you want to restore this Job?',
          buttons: {
            confirm: {
              text: 'Restore Job',
              value: true,
              className: 'btn-success'
            },
            cancel: {
              text: 'Cancel',
              value: false,
              className: 'btn-danger',
              visible: true
            }
          }
        }).then((isConfirm) => {
          if (isConfirm) {
            api.jobsRestore(job_id).then((response) => {
              this.$parent.reload()
            })
          }
        })
      },
      completeJob (job_id) {
        swal({
          title: 'Are you sure you want to complete this Job?',
          text: 'By completing this job you confirm that all the work is complete.',
          icon: 'warning',
          buttons: {
            confirm: {
              text: 'Complete Job',
              value: true,
              className: 'btn-success'
            },
            cancel: {
              text: 'Cancel',
              value: false,
              className: 'btn-danger',
              visible: true
            }
          }
        }).then((isConfirm) => {
          if (isConfirm) {
            api.jobComplete(job_id).then((response) => {
                this.$parent.reload()
            })
          }
        })
      }
    }
  }
</script>
