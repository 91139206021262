<template>
    <transition name="fade" mode="in-out">
        <div v-if="loaded">
            <div class="row">
                <div class="col-lg-6">
                    <div class="card">
                        <h2 class="mb-4">Add Notes</h2>
                        <div class="form-group">
                            <PSText id="title" placeholder="Title" v-model="note.title" :validate="false"></PSText>
                            <PSCkEditor v-model="note.note"></PSCkEditor>
                        </div>
                        <div class="form-actions">
                            <button type="button" class="btn btn-primary" @click="saveNote"><span v-if="note.id">Update</span><span v-else>Add</span> Note</button>
                            <button type="button" class="btn btn-danger" @click="clearNote">Clear</button>
                        </div>
                    </div>

                    <div class="card">
                        <h2 class="mb-4">Scope of works for Job <span v-if="job">{{ job.cartel_job_number }}</span></h2>
                        <div class="form-group">
                            <PSCkEditor v-model="scopeOfWorks" :config="{ height: 600 }"></PSCkEditor>
                        </div>
                        <div class="form-actions">
                            <button type="button" class="btn btn-primary" @click="saveScope">Save</button>
                        </div>
                    </div>

                    <div class="card">
                        <div class="form-actions">
                            <router-link :to="{ name: 'jobs-edit', params: { id: job.id } }" exact class="btn btn-primary">Details</router-link>
                            <router-link :to="{ name: 'jobs-files', params: { id: job.id }  }" exact class="btn btn-primary">Files</router-link>
                            <router-link :to="{ name: 'dashboard' }" exact class="btn btn-danger">Dashboard</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 drag-container" v-dragula="notes" service="orderNotes" drake="only">
                    <div class="card" v-for="(item, index) in notes" :key="item.id">
                        <div class="row">
                            <strong class="mb-0 col-8">{{ item.title }} - {{ item.created ? item.created : 'Just added' }}</strong>
                            <div class="col-4 text-right">
                                <button type="button" class="btn btn-success btn-sm btn-icon" @click="editNote(item)"><i class="zmdi zmdi-edit"></i></button>
                                <button type="button" class="btn btn-danger btn-sm btn-icon" @click="deleteNote(item)"><i class="zmdi zmdi-close"></i></button>
                            </div>
                            <hr>
                        </div>
                        <div v-html="item.note"></div>
                    </div>
                </div>
            </div>
        </div><!-- /.card -->
    </transition>
</template>

<script>
    import api from './../../api'
    import {
        mapState
    } from 'vuex'
    import {
        JOBADD,
        JOBNOTEADD,
        JOBNOTEDELETE
    } from '../../store/mutation-types'
    import store from '../../store'
    import PSText from '../../modules/form/PSText.vue'
    import PSCkEditor from '../../modules/form/PSCkEditor.vue'

    export default {
        components: {
            PSText,
            PSCkEditor
        },
        props: {
            id: {
                required: false,
                default: null
            }
        },
        data() {
            return {
                note: {
                    id: null,
                    job_id: this.id,
                    title: '',
                    note: ''
                },
                notes: [],
                loaded: false,
                scopeOfWorks: '',
                service: null
            }
        },
        computed: {
            ...mapState({
                job: state => state.jobs.job
            })
        },
        created() {
	        const service = this.$dragula.$service
	        service.eventBus.$on('drop', (args) => {
		        api.jobsNotesUpdateOrder({
			        job_id: this.id,
			        notes: this.notes
		        })
	        })
        },
        mounted() {
            let that = this
            api.jobGet(this.id).then(function(response) {
                store.commit(JOBADD, _.cloneDeep(response.data.job))
                that.notes = _.cloneDeep(response.data.job.notes)
                that.loaded = true
            })
            this.$watch('job', () => { this.scopeOfWorks = this.job.scope_of_works }, { deep: true })
        },
        methods: {
            saveNote() {
                if(this.note.note.length > 0) {
                    let that = this
                    if(this.note.id) {
                        api.jobsNotesUpdate(this.note).then(function(response) {
                            store.commit(JOBADD, _.cloneDeep(response.data.job))
                            that.notes = _.cloneDeep(response.data.job.notes)
                            that.clearNote()
                        })
                    } else {
                        store.commit(JOBNOTEADD, _.cloneDeep(this.note))
                        this.notes.unshift(_.cloneDeep(this.note))
                        api.jobsNotesAdd(this.note).then(function(response) {
                            store.commit(JOBADD, _.cloneDeep(response.data.job))
                            that.notes = _.cloneDeep(response.data.job.notes)
                            that.clearNote()
                        })
                    }
                } else {
                    swal('Fail!', 'Please add a note before trying to save', 'info')
                }
            },
            editNote(note) {
                this.note = note
            },
            deleteNote(item) {
                store.commit(JOBNOTEDELETE, item)
                this.notes = _.filter(this.notes, function(note) {
                    return item.id != note.id
                })
                api.jobsNotesDelete(item)
            },
            clearNote() {
                this.note.id = null
                this.note.title = ''
                this.note.note = ''
            },
            saveScope() {
                let that = this
                swal('Saved!', 'Scope of works saved.', 'success')
                api.jobsUpdateScope({
                    id: that.id,
                    scope_of_works: that.scopeOfWorks
                }).then(function(response) {
//                    console.log('saved scope of works')
                    store.commit(JOBADD, response.data.job)
                })
            },
        }
    }
</script>
