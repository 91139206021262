<template>
    <div id="main" class="container-fluid auth-container page-login">
        <div class="row justify-content-center align-items-center">
            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <validation-observer class="card-body" ref="observer" tag="form" @submit.prevent="validateBeforeSubmit">
                        <fieldset>
                            <legend>Cartel Communications</legend>
                            <div class="form-group">
                                <validation-provider name="username" rules="required" v-slot="{ errors, classes }">
                                    <label for="username" class="sr-only">*Username</label>
                                    <input v-model="credentials.username" id="username" type="text" class="form-control" name="username" placeholder="Username" :class="classes" autofocus>
                                    <transition name="fade-grow" mode="out-in">
                                        <div class="invalid-feedback" v-if="errors">{{ errors[0] }}</div>
                                    </transition>
                                </validation-provider>
                            </div>
                            <div class="form-group">
                                <validation-provider name="password" rules="required" v-slot="{ errors, classes }">
                                    <label for="password" class="sr-only">*Password</label>
                                    <input v-model="credentials.password" id="password" type="password" class="form-control" name="password" placeholder="Password" :class="classes">
                                    <transition name="fade-grow" mode="out-in">
                                        <div class="invalid-feedback" v-if="errors">{{ errors[0] }}</div>
                                    </transition>
                                </validation-provider>
                            </div>

                            <div class="form-group">
                                <div class="checkbox">
                                    <input id="remember-me" v-model="credentials.remember" type="checkbox" name="remember">
                                    <label for="remember-me">Remember Me</label>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary">Login</button>
                            <!--<router-link class="btn btn-link" to="/password-reset">Forgot Your Password?</router-link>-->

                            <transition name="fade" mode="out-in">
                                <div class="alert alert-danger mt-3 mb-0" role="alert" v-if="failedLogin">Please check your credentials and try again.</div>
                            </transition>
                        </fieldset>
                    </validation-observer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {
    ALOGIN
  } from '../../store/action-types'
  import store from './../../store'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'

  export default {
    data () {
      return {
        credentials: {
          username: '',
          password: '',
          remember: false
        },
        failedLogin: false
      }
    },
    components: {
      ValidationObserver,
      ValidationProvider
    },
    methods: {
      async validateBeforeSubmit () {
        // console.log(this.$refs)
        let success = await this.$refs.observer.validate()
        this.failedLogin = false
        if (!success) {
          return
        }
        store.dispatch(ALOGIN, this.credentials).then(response => {
          this.$router.push('/')
        }, response => {
          this.failedLogin = true
        })
      }
    }
  }
</script>
