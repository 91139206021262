<template>
    <transition name="fade" mode="in-out">
        <div v-if="loaded">
            <div class="card">
                <h2 class="mb-4">Manage Files for Job <span v-if="job">{{ job.cartel_job_number }}</span></h2>
                <div class="form-group">
                    <label>Drag and drop files to add them to the Job</label>
                    <DropZone ref="dropzone" id="dropzone" :url="url" :headers="headers" @vdropzone-success="success" @vdropzone-error="error"></DropZone>
                </div>
                <div class="form-actions">
                    <div class="form-group">
                        <router-link :to="{ name: 'jobs-edit', params: { id: job.id } }" exact class="btn btn-primary">Details</router-link>
                        <router-link :to="{ name: 'jobs-notes', params: { id: job.id } }" exact class="btn btn-primary">Notes</router-link>
                        <router-link :to="{ name: 'dashboard' }" exact class="btn btn-danger">Dashboard</router-link>
                    </div>
                </div>
            </div>

            <div class="card" v-if="fileTypesLoaded">
                <h2 class="mb4">Provided Files</h2>
                <div class="row">
                    <div class="file mb-4 col-12 col-md-6" v-for="file in job.files" v-if="file.type != fileTypes.uploaded && fileTypesLoaded">
                        <div class="row">
                            <strong class="mb-0 col-8"><a :href="file.fileS3" target="_blank" :download="removeExtension(file.name)">{{ file.name }}</a></strong>
                            <div class="col-4 text-right">
                                <button type="button" class="btn btn-danger btn-sm btn-icon" @click="deleteFile(file)"><i class="zmdi zmdi-close"></i></button>
                            </div>
                            <hr>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-md-3">
                                <a :href="file.fileS3" target="_blank"><img :src="file.thumbnailS3" :alt="file.name" class="img-fluid mb-4"></a>
                            </div>
                            <div class="col-sm-8 col-md-9">
                                <PSTypeAhead :id="'fileSection' + file.id" :preload="false" :multi="false" :value="file.section_selected" @input="updateFileSection($event, file)" :options="fileSections"></PSTypeAhead>
                                <div class="form-group">
                                    <PSCkEditor :value="file.note" @input="debounceUpdateNote($event, file)"></PSCkEditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card" v-if="fileTypesLoaded">
                <h2 class="mb4">Field Uploads</h2>
                <div class="row">
                    <div class="file mb-4 col-12 col-md-6" v-for="file in job.files" v-if="file.type == fileTypes.uploaded && fileTypesLoaded">
                        <div class="row">
                            <strong class="mb-0 col-8"><a :href="file.fileS3" target="_blank" :download="removeExtension(file.name)">{{ file.name }}</a></strong>
                            <div class="col-4 text-right">
                                <button type="button" class="btn btn-danger btn-sm btn-icon" @click="deleteFile(file)"><i class="zmdi zmdi-close"></i></button>
                            </div>
                            <hr>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-md-3">
                                <a :href="file.fileS3" target="_blank"><img :src="file.thumbnailS3" :alt="file.name" class="img-fluid mb-4"></a>
                            </div>
                            <div class="col-sm-8 col-md-9">
                                <div class="form-group">
                                    <PSCkEditor :value="file.note" @input="debounceUpdateNote($event, file)"></PSCkEditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div><!-- /.card -->
    </transition>
</template>

<script>
    import api from './../../api'
    import {
        mapState
    } from 'vuex'
    import {
        JOBADD,
	    JOBFILEADD,
        JOBFILEUPDATE,
        JOBFILEDELETE,
    } from '../../store/mutation-types'
    import store from '../../store'
    import DropZone from '../../modules/form/DropZone.vue'
    import PSCkEditor from '../../modules/form/PSCkEditor.vue'
    import PSTypeAhead from '../../modules/form/PSTypeAhead.vue'
    import { debounce } from 'lodash'

    export default {
        components: {
            DropZone,
            PSCkEditor,
            PSTypeAhead
        },
        props: {
            id: {
                required: false,
                default: null
            }
        },
        data() {
            return {
                loaded: false,
                loadedExistingFile: false,
                fileTypesLoaded: false,
                fileSectionsLoaded: false,
                ignoreEcho: false
            }
        },
        computed: {
            url() {
              return '/api/jobs/' + this.id + '/files'
            },
            ...mapState({
                user: state => state.auth.user,
                job: state => state.jobs.job,
                fileTypes: state => state.typesStatuses.fileTypesNames,
                fileSections: state => state.typesStatuses.fileSections,
                headers(state) {
                    return { Authorization: 'Bearer ' + state.auth.token }
                }
            })
        },
        watch: {
            // for page load
            fileTypes: function(newVal, oldVal) {
              if(newVal.uploaded) {
                  this.fileTypesLoaded = true
              }
            },
        },
        methods: {
        	  removeExtension(filename) {
        		  return filename.replace(/^.*\/|\.[^.]*$/g, '')
            },
            success(file, response, dropzone) {
        		    console.log('successful upload', response.file)
                store.commit(JOBFILEADD, response.file)
                dropzone.removeFile(file)
            },
            error(file, message, xhr) {
	            swal({
			            title: 'Error',
			            text: message,
			            icon: "error",
			            buttons: {
				            confirm: {
					            text: "Reload page",
					            value: true,
					            className: "btn-success"
				            },
				            cancel: {
					            text: "Try again",
					            value: false,
					            className: "btn-danger",
					            visible: true
				            }
			            }
	            }).then((isConfirm) => {
		            if(isConfirm) {
			            document.location.reload();
		            }
		            this.$refs.dropzone.removeFile(file)
	            })
            },
            debounceUpdateNote: debounce(function (note, file) {
                this.updateFileNote(note, file)
            }, 1000),
            updateFileNote(note, file) {
	            store.commit(JOBFILEUPDATE, {file_id: file.id, note: note})
                file.note = note
            	this.ignoreEcho = true
                api.jobsFilesUpdate(file)
            },
            updateFileSection(value, file) {
              store.commit(JOBFILEUPDATE, { file_id: file.id, section_selected: value })
	            this.ignoreEcho = true
	            api.jobsFilesUpdate(file)

            },
            deleteFile(file) {
              store.commit(JOBFILEDELETE, file)
	            this.ignoreEcho = true
	            api.jobsFilesDelete(file)
            }
        },
	    created() {
		    if(this.id != null) {
			    api.jobGet(this.id).then((response) => {
				    this.loaded = true
				    store.commit(JOBADD, response.data.job)
			    })
			    Echo.private('job.' + this.id)
				    .listen('JobUpdated', (e) => {
				    	if(this.ignoreEcho === true || e.trigger_user_id === this.user.id) {
						    this.ignoreEcho = false
                            return
					    }
					    console.log('JobUpdated', e)
					    api.jobGet(this.id).then(function(response) {
						    store.commit(JOBADD, response.data.job)
					    })
				    })
		    }
		    if(this.fileTypes.uploaded) {
			    this.fileTypesLoaded = true
		    }
	    },
	    destroyed() {
		    Echo.leave('Cartel.Job.' + this.id)
	    }
    }
</script>
