import lodash from 'lodash'
import jquery from 'jquery'
import axios from 'axios'
import jsCookie from 'js-cookie'
import pusherJs from 'pusher-js'

window._ = lodash;
window.$ = window.jQuery = jquery;
window.axios = axios;

window.Cookies = jsCookie;

// Echo configuration
window.Pusher = pusherJs;
import Echo from 'laravel-echo'
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '446a09163bf0d37b0924',
    cluster: 'ap1',
    encrypted: true,
    namespace: 'Cartel\\Events'
});
