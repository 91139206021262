import {
  JOBADD,
  FILEUPDATE,
  JOBSADD,
  JOBUPDATE,
  JOBDELETE,
  JOBNOTEADD,
  JOBNOTEDELETE,
  JOBFILEADD,
  JOBFILEUPDATE,
  JOBFILEDELETE,
  JOBSETSTATUSFILTER,
  JOBSETPROJECTMANAGERFILTER
} from './../../mutation-types'

export default {
  [JOBADD] (state, data) {
    state.job = data
  },
  [JOBFILEADD] (state, data) {
    console.log('JOBFILEADD', data)
    state.job.files.push(data)
  },
  [JOBSADD] (state, data) {
    state.jobs = data.data
    state.pagination = _.omit(data, ['data'])
  },
  [JOBUPDATE] (state, data) {
    state.job[data.key] = data.value
  },
  [JOBDELETE] (state, job_id) {
    state.jobs = _.filter(state.jobs, function (job) {
      return job.id !== job_id
    })
  },
  [JOBNOTEADD] (state, data) {
    state.job.notes.unshift(data)
  },
  [JOBNOTEDELETE] (state, data) {
    state.job.notes = _.filter(state.job.notes, function (note) {
      return data.id !== note.id
    })
  },
  [JOBFILEUPDATE] (state, data) {
    let file = _.find(state.job.files, function (item) {
      return data.file_id === item.id
    })
    if (data.note) {
      file.note = data.note
    }
    if (data.section_selected) {
      file.section_selected = data.section_selected
    }
  },
  [JOBFILEDELETE] (state, data) {
    state.job.files = _.filter(state.job.files, function (file) {
      return data.id !== file.id
    })
  },
  [FILEUPDATE] (state, data) {
    let file = _.find(state.job.files, function (file) {
      return data.id === file.id
    })
    if (file) {
      _.each(file, function (item, key) {
        if (data[key]) {
          item = data[key]
        }
      })
    }
  },
  [JOBSETSTATUSFILTER] (state, status) {
    state.filterSet = true
    state.currentStatusFilter = status
  },
  [JOBSETPROJECTMANAGERFILTER] (state, projectManager) {
    state.filterSet = true
    state.currentProjectManagerFilter = projectManager
  }
}
