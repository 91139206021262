import {
    TYPESTATUSSADD
} from './../../mutation-types';

export default {
    [TYPESTATUSSADD](state, data) {
        state.loaded = true
        state.userTypes = data['user-types']
        state.userTypesNames = data['user-types-names']
        state.status = data.status
        state.statusNames = data['status-names']
        state.jobStatus = data['job-status']
        state.jobStatusNames = data['job-status-names']
        state.fileTypes = data['file-types']
        state.fileTypesNames = data['file-types-names']
        state.fileSections = data['file-sections']
        state.fileSectionsNames = data['file-sections-names']
        state.fieldUserLogTypes = data['field-user-log-types']
        state.fieldUserLogTypesNames = data['field-user-log-types-names']
    }
};
