<template>
    <div class="card">
        <table v-if="vehicles != null" class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Registration</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="vehicles.length > 0" v-for="vehicle in vehicles">
                    <td>{{vehicle.name}}</td>
                    <td>{{vehicle.registration}}</td>
                    <td class="table-status"><div v-if="statuses.length">{{ statuses[vehicle.status].name }}</div></td>
                    <td class="table-actions">
                        <button class="btn btn-zmdi-icon" @click="edit(vehicle)"><i class="zmdi mr-1 zmdi-edit text-success"></i></button>
                        <button class="btn btn-zmdi-icon" @click="deleteVehicle(vehicle)" v-if="isAllowedUser">
                            <i class="zmdi mr-1 zmdi-delete text-danger"></i></button>
                        <button v-if="isSuperUser && vehicle.deleted_at !== null" class="btn btn-zmdi-icon" @click="restoreVehicle(vehicle)" title="Full Delete Job">
                            <i class="zmdi mr-1 zmdi-refresh-alt text-primary"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import api from '../../api'
    import {
        mapState
    } from 'vuex'
    import store from '../../store'
    import {
        VEHICLEADD
    } from '../../store/mutation-types'
    import PSText from '../../modules/form/PSText.vue'
    import PSTypeAhead from '../../modules/form/PSTypeAhead.vue'

    export default {
        data() {
            return {}
        },
        computed: mapState({
            vehicles: state => state.vehicles.vehicles,
            statuses: state => state.typesStatuses.status,
          isSuperUser: state => state.auth.user.type === state.typesStatuses.userTypesNames.administrator,
          isAllowedUser: state => state.auth.user.type === state.typesStatuses.userTypesNames.administrator || state.auth.user.type === state.typesStatuses.userTypesNames.office
        }),
        methods: {
            statusChange(value, vehicle) {
                console.log(value, vehicle)
//                api.vehiclesUpdate({
//                    id: vehicle.id,
//                    status: value
//                })
            },
            edit(vehicle) {
                console.log(vehicle)
                store.commit(VEHICLEADD, vehicle)
                this.$router.push({ name: 'vehicles-edit', params: { id: vehicle.id }})
            },
          deleteVehicle (vehicle) {
              swal({
                title: 'Are you sure you want to delete this Vehicle?',
                icon: 'error',
                buttons: {
                  confirm: {
                    text: 'Delete Vehicle',
                    value: true,
                    className: 'btn-danger'
                  },
                  cancel: {
                    text: 'Cancel',
                    value: false,
                    className: 'btn-success',
                    visible: true
                  }
                }
              }).then((isConfirm) => {
                if (isConfirm) {
                  api.vehiclesDelete(vehicle).then((response) => {
                    this.$parent.reload()
                  })
                }
              })
          },
          restoreVehicle (vehicle) {
            swal({
              title: 'Are you sure you want to restore this Vehicle?',
              buttons: {
                confirm: {
                  text: 'Restore Vehicle',
                  value: true,
                  className: 'btn-success'
                },
                cancel: {
                  text: 'Cancel',
                  value: false,
                  className: 'btn-danger',
                  visible: true
                }
              }
            }).then((isConfirm) => {
              if (isConfirm) {
                api.vehiclesRestore(vehicle).then((response) => {
                  this.$parent.reload()
                })
              }
            })
          }
        },
        mounted() {
            api.vehiclesGet()
        },
        watch: {
            search_text: function (val) {
                this.status = []
                this.textSearch()
            }
        },
        components: {
            PSText,
            PSTypeAhead
        },
    }
</script>