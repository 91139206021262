<template>
    <transition name="fade" mode="in-out">
        <div class="card" v-if="loaded">
            <h2 class="mb-4" v-if="id == null">Add a new Job</h2>
            <h2 class="mb-4" v-else>Edit Job <span v-if="job">{{ job.cartel_job_number }}</span></h2>
            <validation-observer ref="observer" tag="form" @submit.prevent v-slot="{ invalid }">
                <div class='row'>
                    <div class='col-lg-6'>
                        <PSTextValidate id="cartel_number" label="Cartel Job" placeholder="Cartel Job Number" v-model="job.cartel_job_number"></PSTextValidate>
                        <PSText id="telstra_number" label="Telstra Job" placeholder="Telstra Job Number" v-model="job.telstra_job_number"></PSText>

                        <div class="form-group">
                            <label for="start">Start Date</label>
                            <datepicker id="start" v-model="job.start" inputClass="form-control"></datepicker>
                        </div>

                        <PSTypeAhead id="jobStatus" label="Status" :preload="true" :multi="false" v-model="job.status" v-if="id != null"></PSTypeAhead>

                        <PSTypeAhead id="address" label="Address" :preload="false" :multi="false" v-model="job.address" :server-side-search="true"></PSTypeAhead>

                        <PSTypeAhead id="project_manager" label="Project Manager" :preload="true" :multi="false" v-model="job.project_manager" search_by="full_name"></PSTypeAhead>

                        <PSTypeAhead id="users" label="Employees / Contractors" :preload="true" :multi="true" v-model="job.users" search_by="full_name"></PSTypeAhead>

                        <PSText id="site_contact_name" label="Site Contact Name" placeholder="Site Contact Name" v-model="job.site_contact_name"></PSText>
                        <PSText id="site_contact_phone" label="Site Contact Phone" placeholder="Site Contact Phone" v-model="job.site_contact_phone"></PSText>
                        <PSText id="site_induction" label="Site Induction" placeholder="Site Induction" v-model="job.site_induction"></PSText>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Note shown with job details</label>
                            <PSCkEditor v-model="job.note_start"></PSCkEditor>
                        </div>
                        <div class="form-group">
                            <label>Note after check in</label>
                            <PSCkEditor v-model="job.note_middle"></PSCkEditor>
                        </div>
                        <div class="form-group">
                            <label>Note for job check out</label>
                            <PSCkEditor v-model="job.note_end"></PSCkEditor>
                        </div>
                    </div>
                </div>
                <div class="form-actions">
                    <div class="form-group">
                        <button type="button" class="btn btn-primary" v-on:click="validateBeforeSubmit('dashboard')" :disabled="invalid">Save</button>
                        <button type="button" class="btn btn-primary" v-on:click="validateBeforeSubmit('jobs-files')">Files</button>
                        <button type="button" class="btn btn-primary" v-on:click="validateBeforeSubmit('jobs-notes')">Notes</button>
                        <router-link :to="{ name: 'dashboard' }" exact class="btn btn-danger">Dashboard</router-link>
                    </div>
                </div>
            </validation-observer>
        </div>
    </transition>
</template>

<script>
  import api from './../../api'
  import {
    JOBADD
  } from '../../store/mutation-types'
  import {
    mapState
  } from 'vuex'
  import store from '../../store'

  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import PSText from '../../modules/form/PSText.vue'
  import PSTextValidate from '../../modules/form/PSTextValidate.vue'
  import PSTypeAhead from '../../modules/form/PSTypeAhead.vue'
  import PSTypeAheadValidate from '../../modules/form/PSTypeAheadValidate.vue'
  import Datepicker from 'vuejs-datepicker'
  import PSCkEditor from '../../modules/form/PSCkEditor.vue'

  export default {
    props: {
      id: {
        required: false,
        default: null
      }
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      PSText,
      PSTextValidate,
      PSTypeAhead,
      PSTypeAheadValidate,
      Datepicker,
      PSCkEditor
    },
    data () {
      return {
        job: {
          cartel_job_number: null,
          telstra_job_number: null,
          status: null,
          start: new Date(),
          address: null,
          project_manager_id: null,
          project_manager: null,
          users: [],
          note_start: null,
          note_middle: null,
          note_end: null,
          site_contact_name: null,
          site_contact_phone: null,
          site_induction: null
        },
        loaded: false,
        usersBefore: []
      }
    },
    computed: mapState({
      status: state => state.typesStatuses.jobStatus
    }),
    watch: {
      '$route': 'initState'
    },
    methods: {
      initState () {
        Object.assign(this.$data, this.$options.data())
        this.init()
      },
      init () {
        if (this.id == null) {
          this.loaded = true
        } else {
          let that = this
          api.jobGet(this.id).then(function (response) {
            that.loaded = true
            that.job = response.data.job
            if (response.data.job?.users.length) {
              that.usersBefore = response.data.job.users
            }
          })
        }
      },
      updateProjectManagerId () {
        if (this.job.project_manager != null) {
          this.job.project_manager_id = this.job.project_manager.id
        } else {
          this.job.project_manager_id = null
        }
      },
      validateBeforeSubmit (next) {
        this.$refs.observer.validate().then(async (success) => {
          if (!success) {
            return
          }

          // check difference between usersBefore and job.users
          let difference = this.usersBefore.filter(x => !this.job.users.includes(x))
          if (difference?.length) {
            const confirm = await swal({
              title: 'Are you sure you want to remove this user from this job?',
              icon: 'warning',
              buttons: {
                confirm: {
                  text: 'Confirm',
                  value: true,
                  className: 'btn-danger'
                },
                cancel: {
                  text: 'Cancel',
                  value: false,
                  className: 'btn-success',
                  visible: true
                }
              }
            })

            if (!confirm) {
              return
            }
          }

          const that = this
          if (this.id == null) {
            api.jobsAdd(this.job).then(function (response) {
              if (response.data.job) {
                store.commit(JOBADD, response.data.job)
                that.$router.push({ name: 'jobs-files', params: { id: response.data.job.id } })
              }
            })
          } else {
            api.jobsUpdate(this.job).then(function (response) {
              if (response.data.job) {
                if (next === 'dashboard') {
                  that.$router.push({ name: next })
                } else {
                  that.$router.push({ name: next, params: { id: response.data.job.id } })
                }
              }
            })
          }
        }).catch(error => {
          console.log('validateBeforeSubmit failed somewhere', error)
        })
      }
    },
    mounted () {
      this.init()
      this.$watch('job.project_manager', this.updateProjectManagerId, { deep: true })
    }
  }
</script>
