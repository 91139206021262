import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import { ACHECKAUTH } from './store/action-types'
import ErrorPage from './components/404.vue'
import Login from './components/auth/Login.vue'
import JobList from './components/jobs/JobList.vue'
import JobsIndex from './components/jobs/Index.vue'
import JobsAddEdit from './components/jobs/AddEdit.vue'
import JobsFiles from './components/jobs/Files.vue'
import JobsNotes from './components/jobs/Notes.vue'
import JobsFieldReport from './components/jobs/FieldReport.vue'
import UsersIndex from './components/users/Index.vue'
import UsersList from './components/users/List.vue'
import UsersAdd from './components/users/Add.vue'
import UsersEdit from './components/users/Edit.vue'
import TimeSheetsIndex from './components/time-sheets/Index.vue'
import TimeSheetsList from './components/time-sheets/List.vue'
import TimeSheetsView from './components/time-sheets/View.vue'
import VehiclesIndex from './components/vehicles/Index.vue'
import VehiclesList from './components/vehicles/List.vue'
import VehiclesAdd from './components/vehicles/Add.vue'
import VehiclesEdit from './components/vehicles/Edit.vue'
import Settings from './components/Settings.vue'

/**
 * Vue Router
 */
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    // Home / Dashboard
    {
      name: 'dashboard',
      path: '/',
      component: JobList, //require('./components/jobs/JobList.vue'),
      meta: {
        title: 'My Jobs',
        hideBreadCrumb: true
      },
      props: {
        pm: true,
        status: '0|1|2'
      }
    },

    // Auth
    {
      name: 'login',
      path: '/login',
      component: Login, // require('./components/auth/Login.vue'),
      meta: {
        title: 'Login'
      }
    },

    // Jobs
    {
      path: '/jobs',
      component: JobsIndex, //require('./components/jobs/Index.vue'),
      meta: {
        title: 'Jobs',
        showRoute: 'jobs-dashboard'
      },
      children: [
        {
          path: '',
          name: 'jobs-dashboard',
          component: JobList, //require('./components/jobs/JobList.vue'),
          meta: {
            title: 'Jobs',
            icon: 'home',
            hideBreadCrumb: true
          }
        },
        {
          path: 'add',
          name: 'jobs-add',
          component: JobsAddEdit, //require('./components/jobs/AddEdit.vue'),
          meta: {
            title: 'New'
          }
        },
        {
          path: ':id/files',
          name: 'jobs-files',
          props: true,
          component: JobsFiles, //require('./components/jobs/Files.vue'),
          meta: {
            title: 'Manage Files'
          }
        },
        {
          path: ':id/notes',
          name: 'jobs-notes',
          props: true,
          component: JobsNotes, //require('./components/jobs/Notes.vue'),
          meta: {
            title: 'Notes'
          }
        },
        {
          path: ':id/edit',
          name: 'jobs-edit',
          props: true,
          component: JobsAddEdit, //require('./components/jobs/AddEdit.vue'),
          meta: {
            title: 'Edit'
          }
        },
        {
          path: ':id/field-report',
          name: 'jobs-field-report',
          props: true,
          component: JobsFieldReport, //require('./components/jobs/FieldReport.vue'),
          meta: {
            title: 'Field Report'
          }
        }
      ]
    },

    // Users
    {
      path: '/users',
      component: UsersIndex, //('./components/users/Index.vue'),
      meta: {
        title: 'Users',
        showRoute: 'users'
      },
      children: [
        {
          path: '',
          name: 'users',
          component: UsersList, //require('./components/users/List.vue'),
          meta: {
            title: 'Users',
            hideBreadCrumb: true
          }
        },
        {
          path: 'add',
          name: 'users-add',
          component: UsersAdd, //require('./components/users/Add.vue'),
          meta: {
            title: 'Add'
          }
        },
        {
          path: ':id/edit',
          name: 'users-edit',
          props: true,
          component: UsersEdit, //require('./components/users/Edit.vue'),
          meta: {
            title: 'Edit'
          }
        }
      ]
    },

    {
      path: '/time-sheets',
      component: TimeSheetsIndex, //require('./components/time-sheets/Index.vue'),
      meta: {
        title: 'Time Sheets',
        showRoute: 'time-sheets'
      },
      children: [
        {
          path: '',
          name: 'time-sheets',
          component: TimeSheetsList, //require('./components/time-sheets/List.vue'),
          meta: {
            title: 'Time Sheets',
            hideBreadCrumb: true
          }
        },
        {
          path: ':user_id/view/:date',
          name: 'time-sheets-view',
          props: true,
          component: TimeSheetsView, //require('./components/time-sheets/View.vue'),
          meta: {
            title: 'View'
          }
        }
      ]
    },

    // Vehicles
    {
      path: '/vehicles',
      component: VehiclesIndex, //require('./components/vehicles/Index.vue'),
      meta: {
        title: 'Vehicles',
        showRoute: 'vehicles'
      },
      children: [
        {
          path: '',
          name: 'vehicles',
          component: VehiclesList, //require('./components/vehicles/List.vue'),
          meta: {
            title: 'Vehicles',
            hideBreadCrumb: true
          }
        },
        {
          path: 'add',
          name: 'vehicles-add',
          component: VehiclesAdd, //require('./components/vehicles/Add.vue'),
          meta: {
            title: 'Add'
          }
        },
        {
          path: ':id/edit',
          name: 'vehicles-edit',
          props: true,
          component: VehiclesEdit, //require('./components/vehicles/Edit.vue'),
          meta: {
            title: 'Edit'
          }
        }
      ]
    },

    // User Settings
    {
      name: 'user-settings',
      path: '/settings',
      component: Settings, //require('./components/Settings.vue'),
      meta: {
        title: 'User Settings',
        showRoute: 'User Settings'
      }
    },

    // Catch all
    {
      name: 'not-found',
      path: '*',
      component: ErrorPage,
      meta: {
        title: 'Whoops!',
        showRoute: 'Not Found'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!store.state.auth.authenticated) {
    if (to.path === '/login') {
      next()
      return
    }
    store.dispatch(ACHECKAUTH).then(function () {
      if (to.path === '/login') {
        next('/')
        return
      }
      next()
    }, function () {
      if (to.path !== '/login') {
        next('/login')
        return
      }
      next()
    })
  } else {
    if (to.path === '/login') {
      next('/')
      return
    }
    next()
  }
})

export default router
