import {
    SETTOKEN,
    SETUSER,
    LOGIN,
    LOGOUT
} from './../../mutation-types';

export default {
    [SETTOKEN](state, token) {
        Echo.connector.pusher.config.auth.headers['Authorization'] = 'Bearer ' + token;
        state.token = token
    },
    [SETUSER](state, user) {
        state.user = user
    },
    [LOGIN](state, data) {
        state.authenticated = true
    },
    [LOGOUT](state) {
        Cookies.remove('token')
        state.user = null
        state.token = null
        state.authenticated = false
    }
};
