import {
  ACHECKAUTH,
  ALOGIN
} from './../../action-types'
import {
  SETTOKEN,
  SETUSER,
  LOGIN,
  LOGOUT,
  TYPESTATUSSADD
} from './../../mutation-types'
import api from './../../../api'
import store from '../../index'

export default {
  [ACHECKAUTH] ({ commit, state }) {
    return new Promise((resolve, reject) => {
      let token = Cookies.get('token')
      if (typeof token === 'undefined') {
        console.error('cookie not found!')
        reject()
        return
      }
      commit(SETTOKEN, Cookies.get('token'))
      api.getAuthenticatedUser().then(function (response) {
        if (!response.data || !response.data.user) {
          reject()
          return
        }

        commit(SETUSER, response.data.user)
        commit(LOGIN)

        api.typesStatuses()
        api.projectManagers()

        resolve()
      }, function (response) {
        commit(LOGOUT)
        console.log('getuser fail action', response)
        reject(response)
      })
    })
  },
  [ALOGIN] ({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      api.login(credentials).then(function (response) {
        if (!response.data || !response.data.token) {
          reject()
          return
        }

        var expires = 1
        if (credentials.remember) {
          expires = 28
        }

        Cookies.set('token', response.data.token, { expires: expires })

        commit(SETTOKEN, response.data.token)
        commit(LOGIN)

        api.typesStatuses()
        api.projectManagers()

        resolve(response)
        api.getAuthenticatedUser().then(function (response) {
          commit(SETUSER, response.data.user)
        }, function (response) {
          console.log('getuser fail action', response)
          reject(response)
        })
      }, function (response) {
        console.log('login fail action', response)
        reject(response)
      })
    })

  }
}
