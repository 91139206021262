<template>
    <header class="site-header">
        <div class="breadcrumb">
            <ol>
                <router-link  class="breadcrumb-item mr-2" exact v-for="(crumb, i) in breadcrumbs" :key="i + '-' + crumb.name ? crumb.name : crumb.meta.showRoute" :to="{ name: crumb.meta.showRoute ? crumb.meta.showRoute : crumb.name }" tag="li">
                    <a>
                        <span v-if="crumb.meta.icon" class="zmdi mr-1" :class="getBreadcrumbClass(crumb)"></span>
                        <span>{{ crumb.meta.title }}</span>
                    </a>
                </router-link>
            </ol>

            <ul>
                <li v-for="action in mergedActions" :key="action.name">
                    <router-link :to="{ name: action.name }" class="btn btn-sm btn-scondary">
                        <i class="zmdi mr-1" :class="getActionClass(action)"></i>
                        <span>{{ action.title }}</span>
                    </router-link>
                </li>
            </ul>
        </div><!-- /.breadcrumb -->
    </header><!-- /.site-header -->
</template>
<script>
    export default {

        computed: {
            breadcrumbs() {
                var crumbs = []
                for(var i = 0; i < this.$route.matched.length; i++) {
                    if(this.$route.matched[i].name != 'jobs-dashboard' && !this.$route.matched[i].meta.hideBreadCrumb) {
                        crumbs.push(this.$route.matched[i])
                    }
                }
                return _.concat({
                    name: 'jobs-dashboard',
                    meta: {
                        title: 'Dashboard',
                        icon: 'home'
                    }
                }, crumbs)
            },
            mergedActions() {
                let actions = [
                    {
                        title: 'New Job',
                        icon: 'plus',
                        color: 'success',
                        name: 'jobs-add'
                    },
                    {
                        title: 'New User',
                        icon: 'plus',
                        color: 'success',
                        name: 'users-add'
                    },
                    {
                        title: 'New Vehicle',
                        icon: 'plus',
                        color: 'success',
                        name: 'vehicles-add'
                    }
                ]
                if('actions' in this.$route.meta) {
                    return _.concat(actions, this.$route.meta.actions)
                }
                return actions
            }
        },
        methods: {
            getBreadcrumbClass(crumb) {
                return 'zmdi-' + crumb.meta.icon
            },
            getActionClass(action) {
                return 'zmdi-' + action.icon + ' text-' + action.color
            }
        },
        mounted() { }
    }
</script>
