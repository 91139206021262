import Vue from 'vue'
import axios from 'axios'
import store from './store'
import {
  LOGOUT,
  JOBSADD,
  JOBADD,
  FILEUPDATE,
  USERADD,
  USERSADD,
  PMUSERSADD,
  VEHICLEADD,
  VEHICLESADD,
  LOADERINCREMENT,
  LOADERDECREMENT,
  TIMESHEETUSERS,
  TYPESTATUSSADD,
  VEHICLEDELETE
} from './store/mutation-types'
import swal from 'sweetalert'
import router from './router'

axios.defaults.baseURL = import.meta.env.VITE_APP_URL + '/api/'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  config.headers['X-CSRF-TOKEN'] = window.Cartel.csrfToken
  if (store.state.auth.token != null) {
    config.headers['Authorization'] = 'Bearer ' + store.state.auth.token
  }
  // Create a loader on every request
  // if (config['url'] !== 'type-ahead' && config['url'] !== 'users/validate' && config['url'].search('notes-update-order') === -1) {
  if (config['url'].split('/')[2] === 'download_field_files') {
    store.commit(LOADERINCREMENT)
  }
  return config
}, function (error) {
  store.commit(LOADERDECREMENT)
  return Promise.reject(error)
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  store.commit(LOADERDECREMENT)
  return response
}, function (error) {
  store.commit(LOADERDECREMENT)
  console.log('API REQUEST ERROR', error.response)
  switch (error.response.status) {
    case 401:
    case 403:
      console.log('UNAUTHORISED LOGGING OUT')
      store.commit(LOGOUT)
      router.push('/login')
      break
    case 404:
    case 500:
    case 501:
      let $dom = $('<div/>')
      $dom.html('<p>There was an error completing the request.  Please report this issue, noting the steps taken to reproduce it if possible.</p>')
      if (error.response.data instanceof Blob && error.response.data.type === 'application/json') {
        let reader = new FileReader()
        reader.addEventListener('loadend', () => {
          let response = JSON.parse(reader.result)
          console.log(response)
          $dom.html('<p>There was an error completing the request.  Please report this issue, including the message below and noting the steps taken to reproduce it if possible.</p><p class="small alert-danger">' + response.message + '</p>')
        })
        reader.readAsText(error.response.data)
      }
      swal({
        title: 'Response Error - ' + error.response.status,
        content: $dom[0],
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Reload page',
            value: true,
            className: 'btn-success'
          },
          cancel: {
            text: 'Try again',
            value: false,
            className: 'btn-danger',
            visible: true
          }
        }
      }).then((isConfirm) => {
        if (isConfirm) {
          document.location.reload()
        }
      })
      break
  }
  return Promise.reject(error)
})

export default {
  // Auth
  login (credentials) {
    return axios.post('authenticate', credentials)
  },
  getAuthenticatedUser () {
    return axios.get('get-authenticated-user')
  },

  // Jobs
  jobsGet (status = null) {
    if (status == null) {
      axios.get('jobs').then(function (response) {
        store.commit(JOBSADD, response.data)
      }, function () {
        Vue.router.push('/jobs')
      })
    } else {
      axios.get('jobs/status/' + status).then(function (response) {
        store.commit(JOBSADD, response.data)
      }, function () {
        Vue.router.push('/jobs')
      })
    }
  },
  jobsActive () {
    axios.get('jobs/active').then(function (response) {
      store.commit(JOBSADD, response.data)
    }, function (response) {
      Vue.router.push('/jobs')
    })
  },
  jobGet (id) {
    return axios.get('jobs/' + id)
  },
  async jobGetUserLogs (id) {
    let response = await axios.get('jobs/' + id + '/user-logs')
    return response.data
  },
  async jobGetFiles (id) {
    let response = await axios.get('jobs/' + id + '/files')
    return response.data
  },
  jobsAdd (data) {
    return axios.post('jobs', data)
  },
  jobsUpdate (data) {
    delete data.files
    return axios.post('jobs/' + data.id, data)
  },
  jobsDelete (job_id) {
    return axios.delete('jobs/' + job_id)
  },
  jobsFullDelete (job_id) {
    return axios.delete('jobs/full/' + job_id)
  },
  jobsRestore (job_id) {
    return axios.post('jobs/restore/' + job_id)
  },
  jobComplete (job_id) {
    return axios.post('jobs/' + job_id + '/complete')
  },
  jobStatus (job_id, status) {
    return axios.post('jobs/' + job_id + '/status/' + status)
  },
  jobsUpdateScope (data) {
    return axios.post('jobs/' + data.id + '/update-scope', data)
  },
  jobsSearch (type, value) {
    axios.get('jobs/by/' + type + '/' + value).then(function (response) {
    })
  },
  jobsNotesAdd (data) {
    return axios.post('jobs/' + data.job_id + '/notes', data)
  },
  jobsNotesUpdate (data) {
    return axios.post('jobs/' + data.job_id + '/notes/' + data.id, data)
  },
  jobsNotesUpdateOrder (data) {
    return axios.post('jobs/' + data.job_id + '/notes-update-order', data)
  },
  jobsNotesDelete (data) {
    axios.delete('jobs/' + data.job_id + '/notes/' + data.id).then(function (response) {
      store.commit(JOBADD, response.data.job)
    })
  },
  jobsFilesUpdate (data) {
    axios.post('jobs/' + data.job_id + '/files/' + data.id, data).then(function (response) {
      store.commit(FILEUPDATE, response.data.file)
    })
  },
  jobsFilesDelete (data) {
    return axios.delete('jobs/' + data.job_id + '/files/' + data.id)
  },
  jobsFieldFiles (job_id) {
    return axios.get('jobs/' + job_id + '/download_field_files', { responseType: 'blob' }).then(function (response) {
      // console.log(response.headers)
      var result = document.createElement('a'),
        contentDisposition = response.headers['content-disposition'] || '',
        filename = contentDisposition.split('filename=')[1],
        url = window.URL.createObjectURL(response.data)

      filename = filename.replace(/"/g, '')

      result.href = url
      result.target = '_self'
      result.download = filename

      document.body.appendChild(result)
      result.click()

      setTimeout(function () {
        document.body.removeChild(result)
        window.URL.revokeObjectURL(url)
      }, 250)
    })
  },

  // Users
  // Validate username
  usersValidate (data) {
    return axios.post('users/validate', data)
  },
  usersGet (id = null) {
    if (id == null) {
      axios.get('users/web').then(function (response) {
        store.commit(USERSADD, response.data)
      })
    } else {
      axios.get('users/web/' + id).then(function (response) {
        store.commit(USERADD, response.data)
      })
    }
  },
  usersAdd (data) {
    axios.post('users', data).then(function (response) {
      if (response.data.id) {
        Vue.router.push('/users')
      }
    })
  },
  usersUpdate () {
    let user = store.state.users.user
    axios.post('users/' + user.id, user)
  },
  usersDelete (user) {
    return axios.delete('users/' + user.id)
  },
  usersRestore (user) {
    return axios.post('users/restore/' + user.id)
  },
  // usersSearch(type,value) {
  //     axios.get('users/by/' + type + '/' + value).then(function(response) {
  //     }, function (response) {
  //         console.log('fail', response)
  //     })
  // },
  projectManagers () {
    axios.get('users/project-managers').then(function (response) {
      store.commit(PMUSERSADD, response.data)
    })
  },

  usersTimesheet (date, user_id) {
    return axios.get('users/' + user_id + '/timesheet/' + date)
  },
  timesheetUsers (date) {
    axios.get('users/timesheets/' + date).then(function (response) {
      store.commit(TIMESHEETUSERS, response.data)
    })
  },
  timesheetsSave (type, data) {
    return axios.post('timesheet/' + data.timesheet.id, data)
  },
  timesheetsPDF (type, data) {
    return axios.post('timesheet/' + type + '/' + data.timesheet.id, data, { responseType: 'blob' }).then(function (response) {
      var result = document.createElement('a'),
        contentDisposition = response.headers['content-disposition'] || '',
        filename = contentDisposition.split('filename=')[1],
        url = window.URL.createObjectURL(response.data)

      filename = filename.replace(/"/g, '')

      result.href = url
      result.target = '_self'
      result.download = filename

      document.body.appendChild(result)
      result.click()

      setTimeout(function () {
        document.body.removeChild(result)
        window.URL.revokeObjectURL(url)
      }, 250)
    })
  },

  // Vehicles
  vehiclesGet (id = null) {
    if (id == null) {
      axios.get('vehicles').then(function (response) {
        store.commit(VEHICLESADD, response.data.vehicles)
      })
    } else {
      axios.get('vehicles/' + id).then(function (response) {
        store.commit(VEHICLEADD, response.data.vehicle)
      })
    }
  },
  vehiclesAdd (data) {
    axios.post('vehicles', data)
  },
  vehiclesUpdate () {
    let vehicle = store.state.vehicles.vehicle
    axios.post('vehicles/' + vehicle.id, vehicle).then(function (response) {
      store.commit(VEHICLESADD, response.data.vehicles)
    })
  },
  vehiclesSearch (type, value) {
    axios.get('vehicles/by/' + type + '/' + value).then(function (response) {
      store.commit(VEHICLESADD, response.data.vehicles)
    })
  },
  vehiclesDelete (data) {
    axios.delete('vehicles/' + data.id).then(function (response) {
      store.commit(VEHICLEDELETE, data.id)
      router.go()
    })
  },
  vehiclesRestore (data) {
    axios.post('vehicles/restore/' + data.id).then(function (response) {
      router.go()
    })
  },

  //TYPEAHEADS
  typeAhead (data) {
    return axios.post('type-ahead', data)
  },

  // Preload select
  preload (data) {
    return axios.post('preload', data)
  },

  // Types & Statuses
  typesStatuses () {
    return axios.get('types-statuses').then(function (response) {
      store.commit(TYPESTATUSSADD, response.data)
    })
  }
}
