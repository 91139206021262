<template>
    <div class="d-flex flex-column">
        <div><strong><b>Uploaded Files:</b></strong> {{ rowData.uploaded_files_count }}</div>
        <div>PDF Files: {{ rowData.pdf_files_count }}</div>
        <div>Image Files: {{ rowData.image_files_count }}</div>
    </div>
</template>
<script>
  export default {
    props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    }
  }
</script>
