<template>
    <div>
        <div class="card">
            <div class="card-body">
                <h1 v-if="user">{{ user.full_name }}</h1>
                <h2 v-if="start">{{ start | dateFormatted }} - {{ end | dateFormatted }}</h2>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h3>Run sheet</h3>
                <table class="table table-hover table-timesheet">
                    <thead>
                    <tr>
                        <th>Job</th>
                        <th>Actual</th>
                        <th>Reported</th>
                        <th>Time</th>
                        <th>Rate</th>
                        <th>Subtotal</th>
                        <th>Notes</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="entry in timesheet_job_entries" :class="{ muted: entry.total_time.hours === 0 && entry.total_time.minutes === 0 }">
                        <td class="job-details d-flex flex-column">
                            <div>
                                <div class="address">{{ entry.job.address }}</div>
                                <div class="project-manager" v-if="entry.job.project_manager != null">
                                    <strong>Project Manager</strong> {{ entry.job.project_manager.full_name }}
                                </div>
                                <div class="job-number">
                                    <strong>Cartel Job Number:</strong> {{ entry.job.cartel_job_number }}
                                </div>
                            </div>
                            <br/>
                            <div>
                                <div v-for="(value, key) in entry.vehicles">
                                    <div>{{ value }} (<strong>{{ key }}</strong>)</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <strong>Start</strong> {{ getDateTime(entry.actual_start) }}<br>
                            <strong>Finish</strong> {{ getDateTime(entry.actual_finish) }}<br>
                            <strong>Total</strong> {{ getTimeDifference(entry.actual_start, entry.actual_finish) }}
                        </td>
                        <td>
                            <strong>Start</strong> {{ getDateTime(entry.reported_start) }}<br>
                            <strong>Finish</strong> {{ getDateTime(entry.reported_finish) }}<br>
                            <strong>Total</strong> {{ getTimeDifference(entry.reported_start, entry.reported_finish) }}
                        </td>
                        <td class="time-total">
                            <div class="d-flex flex-nowrap">
                                <input type="number" v-model="entry.total_time.hours" @input="calculateSubTotal(entry)" class="form-control form-control-sm"/> :
                                <input type="number" v-model="entry.total_time.minutes" @input="calculateSubTotal(entry)" class="form-control form-control-sm"/>
                            </div>
                        </td>
                        <td class="rate">
                            <div class="input-group input-group-sm">
                                <div class="input-group-addon">$</div>
                                <input type="number" v-model="entry.rate" @input="calculateSubTotal(entry)" class="form-control form-control-sm"/>
                            </div>
                        </td>
                        <td class="subtotal">
                            <div class="input-group input-group-sm">
                                <div class="input-group-addon">$</div>
                                <input type="number" v-model="entry.total" @input="calculateRate(entry)" class="form-control form-control-sm"/>
                            </div>
                        </td>
                        <td class="notes" v-html="entry.notes"></td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colspan="3" class="text-right">TOTALS</th>
                        <th>{{ timesheetJobTotalTime }}</th>
                        <th></th>
                        <th>{{ timesheetJobTotalMoney }}</th>
                        <th></th>
                    </tr>
                    </tfoot>
                </table>
                <button class="btn btn-primary" @click="saveJob" :class="{ disabled: !timesheet_job_changed }" :disabled="!timesheet_job_changed">Save Changes</button>
                <button class="btn btn-primary" @click="pdfJob" :class="{ disabled: timesheet_job_changed }" :disabled="timesheet_job_changed">Download PDF Run sheet</button>
            </div>
        </div>

        <div class="card" v-if="isEmployee">
            <div class="card-body">
                <h3>Employee Time sheet</h3>
                <table class="table table-hover table-timesheet">
                    <thead>
                    <tr>
                        <th>Actual</th>
                        <th>Reported</th>
                        <th>Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(entry, i) in timesheet_employee_entries" :class="{ muted: entry.total_time.hours === 0 && entry.total_time.minutes === 0 }">
                        <td>
                            <strong>Clock on</strong> {{ getDateTime(entry.actual_start) }}<br>
                            <strong>Clock off</strong> {{ getDateTime(entry.actual_finish) }}<br>
                            <strong>Total</strong> {{ getTimeDifference(entry.actual_start, entry.actual_finish) }}
                        </td>
                        <td>
                            <strong>Clock on</strong> {{ getDateTime(entry.reported_start) }}<br>
                            <strong>Clock off</strong> {{ getDateTime(entry.reported_finish) }}<br>
                            <strong>Total</strong> {{ getTimeDifference(entry.reported_start, entry.reported_finish) }}
                        </td>
                        <td class="time-total">
                            <input type="number" v-model="entry.total_time.hours" @input="employeeTimeChanged" class="form-control-sm"/> :
                            <input type="number" v-model="entry.total_time.minutes" @input="employeeTimeChanged" class="form-control-sm"/>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colspan="2" class="text-right">TOTAL TIME</th>
                        <th>{{ timesheetEmployeeTotalTime }}</th>
                    </tr>
                    </tfoot>
                </table>
                <button class="btn btn-primary" @click="saveEmployee" :class="{ disabled: !timesheet_employee_changed }" :disabled="!timesheet_employee_changed">Save Changes</button>
                <button class="btn btn-primary" @click="pdfEmployee" :class="{ disabled: timesheet_employee_changed }">Download PDF Time sheet</button>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h3>Log Summary</h3>
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>Job</th>
                        <th>Type</th>
                        <th>Actual</th>
                        <th>Reported</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="entry in summary">
                        <td>{{ entry.job != null ? entry.job.cartel_job_number : '' }}</td>
                        <td>{{ getLogType(entry.type) }}</td>
                        <td>{{ moment(entry.created_at).format('D MMMM h:mm:ss a') }}</td>
                        <td>{{ entry.reported != null ? moment(entry.reported).format('D MMMM h:mm:ss a') : '' }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../api'
  import {
    mapState
  } from 'vuex'
  import store from '../../store'
  import {
    USERADD
  } from '../../store/mutation-types'
  import PSText from '../../modules/form/PSText.vue'
  import PSTypeAhead from '../../modules/form/PSTypeAhead.vue'
  import moment from 'moment'

  export default {
    props: {
      user_id: {
        required: true
      },
      date: {
        required: true
      }
    },
    data () {
      return {
        start: null,
        end: null,
        summary: [],
        timesheet_job: [],
        timesheet_job_entries: [],
        timesheet_job_changed: false,
        timesheet_employee: [],
        timesheet_employee_entries: [],
        timesheet_employee_changed: false,
        job_users: [],
        moment: moment,
      }
    },
    computed: {
      isEmployee () {
        if (this.user && this.userTypes) {
          return this.user.type == this.userTypes.employee
        }
        return false
      },
      timesheetJobTotalTime () {
        var mapTotal = _.map(this.timesheet_job_entries, function (item) {
          return item.total_time.hours * 60 + parseInt(item.total_time.minutes)
        })

        var total = _.reduce(mapTotal, function (sum, item) {
          return sum + item
        })

        if (total) {
          return this.pad(Math.floor(total / 60)) + ':' + this.pad(total % 60)
        }

        return '00:00'
      },
      timesheetJobTotalMoney () {
        var mapTotal = _.map(this.timesheet_job_entries, function (item) {
          return (item.total != null) ? parseFloat(item.total) : 0
        })

        var total = _.reduce(mapTotal, function (sum, item) {
          return sum + item
        })

        if (total) {
          return '$' + parseFloat(total).toFixed(2)
        }
        return '$0.00'
      },
      timesheetEmployeeTotalTime () {
        var mapTotal = _.map(this.timesheet_employee_entries, function (item) {
          return item.total_time.hours * 60 + parseInt(item.total_time.minutes)
        })

        var total = _.reduce(mapTotal, function (sum, item) {
          return sum + item
        })

        if (total) {
          return this.pad(Math.floor(total / 60)) + ':' + this.pad(total % 60)
        }

        return '00:00'
      },
      ...mapState({
        user: state => _.omit(state.users.user, ['jobs']),
        weekCurrent: state => state.users.weekCurrent,
        userTypes: state => state.typesStatuses.userTypesNames,
        statuses: state => state.typesStatuses.status,
        fieldUserLogTypes: state => state.typesStatuses.fieldUserLogTypes,
        isSuperUser: state => state.auth.user.type == state.typesStatuses.userTypes.administrator
      })
    },
    methods: {
      getLogType (type) {
        let logType = _.find(this.fieldUserLogTypes, function (item) {
          return item.id == type
        })
        return logType ? logType.name : ''
      },
      getDate (date) {
        return moment(date).format('D/MM/YY')
      },
      getDateTime (date) {
        return moment(date).format('D/MM/YY H:mm:ss')
      },
      pad (num) {
        if (num < 10) {
          return ('0' + num).slice(-2)
        }
        return num
      },
      getTimeDifferenceObject (start, end) {
        var secs = moment(end).unix() - moment(start).unix()
        var minutes = Math.floor(secs / 60)
        secs = secs % 60
        var hours = Math.floor(minutes / 60)
        minutes = minutes % 60
        return {
          hours: hours,
          minutes: minutes,
          seconds: secs
        }
      },
      getTimeDifference (start, end) {
        var timeDifference = this.getTimeDifferenceObject(start, end)
        return this.pad(timeDifference.hours) + ':' + this.pad(timeDifference.minutes) + ':' + this.pad(timeDifference.seconds)
      },
      calculateSubTotal (entry) {
        this.timesheet_job_changed = true
        var hours = entry.total_time.hours ? parseInt(entry.total_time.hours) : 0
        var minutes = entry.total_time.minutes ? parseInt(entry.total_time.minutes) : 0
        var rate = entry.rate ? parseFloat(entry.rate) : 0
        entry.total = ((hours + (minutes / 60)) * rate).toFixed(2)
      },
      calculateRate (entry) {
        this.timesheet_job_changed = true
        var hours = entry.total_time.hours ? parseInt(entry.total_time.hours) : 0
        var minutes = entry.total_time.minutes ? parseInt(entry.total_time.minutes) : 0
        var total = entry.total ? parseFloat(entry.total) : 0
        entry.rate = (total / (hours + (minutes / 60))).toFixed(2)
      },
      employeeTimeChanged () {
        this.timesheet_employee_changed = true
      },
      saveJob () {
        this.timesheet_job_changed = false
        api.timesheetsSave('job', {
          timesheet: this.timesheet_job,
          entries: this.timesheet_job_entries,
          start: this.start,
          end: this.end,
          user: this.user
        })
//                .then(function(response) {
//                    console.log(response)
//                })
      },
      pdfJob () {
        api.timesheetsPDF('job', {
          timesheet: this.timesheet_job,
          entries: this.timesheet_job_entries,
          start: this.start,
          end: this.end,
          user: this.user
        })
      },
      saveEmployee () {
        this.timesheet_employee_changed = false
        api.timesheetsSave('employee', {
          timesheet: this.timesheet_employee,
          entries: this.timesheet_employee_entries,
          start: this.start,
          end: this.end,
          user: this.user
        })
//                .then(function(response) {
//                    console.log(response)
//                })
      },
      pdfEmployee () {
        api.timesheetsPDF('employee', {
          timesheet: this.timesheet_employee,
          entries: this.timesheet_employee_entries,
          start: this.start,
          end: this.end,
          user: this.user
        })
      }
    },
    mounted () {
      let that = this
      api.usersGet(this.user_id)
      api.usersTimesheet(this.date, this.user_id).then(function (response) {
        // console.log("api ",response)
        that.start = response.data.start
        that.end = response.data.end
        that.summary = response.data.summary
        that.timesheet_job = response.data.timesheet_job
        that.timesheet_job_entries = _.map(response.data.timesheet_job_entries, function (item) {
          item.total_time = {
            hours: Math.floor(item.total_time / 60),
            minutes: item.total_time % 60
          }
          if (item.total == null) {
            that.timesheet_job_changed = true
          }
          return item
        })
        that.timesheet_employee = response.data.timesheet_employee
        that.timesheet_employee_entries = _.map(response.data.timesheet_employee_entries, function (item) {
          item.total_time = {
            hours: Math.floor(item.total_time / 60),
            minutes: item.total_time % 60
          }
          return item
        })

        if (response.data.missing_data !== '') {
          swal('Missing Data', response.data.missing_data, '\n\rPlease contact the employee/contractor to rectify the issue.', 'error')
        }
      })
    },
    components: {
      PSText,
      PSTypeAhead
    },
  }
</script>
