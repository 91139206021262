<template>
    <select class="form-control" v-model="status" @change="setStatus">
        <option v-if="statuses.length > 0" v-for="item in statuses" :value="item.id">{{ item.name }}</option>
    </select>
</template>
<script>
    import api from '../../api'
    import {
        mapState
    } from 'vuex'

    export default {
        props: {
            rowData: {
                type: Object,
                required: true
            },
            rowIndex: {
                type: Number
            }
        },
        computed: mapState({
            statuses: state => state.typesStatuses.jobStatus
        }),
        data() {
            return 	{
            	status: null
            }
        },
        created() {
	        this.status = this.rowData.status
        },
        methods: {
	        rowDataChange(newVal, oldVal) {
		        this.status = newVal.status
            },
            setStatus() {
            	api.jobStatus(this.rowData.id, this.status).then((response) => { this.$parent.$emit('setJobStatus', response.data) })
            }
        },
        watch: {
        	rowData: 'rowDataChange'
        }
    }
</script>
