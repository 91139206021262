<template>
    <nav class="site-navbar navbar-expand-lg fixed-top navbar-light bg-faded">
        <div class="navbar-header">
            <router-link class="navbar-brand" to="/">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310" width="30px" height="30px"><title>Cartel Communications</title><path d="M310,124.9382c-36.0391,18.35-75.8931,43.4744-115.85,73.9692C145.6663,235.91,104.84,274.8538,75.8665,310H310Z" fill="#293885"/><path d="M173.79,182.6874C106.575,227.7393,45.26,266.7216,0,293.625V310H31.4526C68.64,276.9863,122.2617,234.7647,183.97,190.7974c45.1554-32.1732,88.4883-60.8983,126.03-83.94V88.1432C271.13,116.2485,224.3291,148.8133,173.79,182.6874Z" fill="#293885"/><path d="M153.4309,166.4675C100.1063,198.67,47.5146,224.0569,0,241.6462v22.48c47.7852-21.3512,104.4626-52.0175,163.6107-89.5483C219.7065,138.9833,269.9822,102.319,310,68.7839V48.7662C269.0146,88.101,215.2686,129.124,153.4309,166.4675Z" fill="#293885"/><path d="M310,28.0742V0H0V223.0386c45.9268-14.9718,94.6862-36.6421,143.2512-64.6811C211.0173,119.2327,268.2336,73.582,310,28.0742Z" fill="#ec7523"/></svg>
                <span class="brand-name hidden-fold">Cartel Communications</span>
            </router-link>
            <b-navbar-toggle target="app-navbar-collapse"><span class="navbar-toggler-icon"></span></b-navbar-toggle>
        </div>
        <b-collapse is-nav id="app-navbar-collapse">
            <!-- Left Nav-->
            <ul class="navbar-nav mr-auto mt-2 mt-md-0">
                <router-link tag="li" :to="{ name: 'jobs-dashboard' }" exact><a class="nav-link">Jobs</a></router-link>
                <router-link tag="li" to="/users" exact><a class="nav-link">Users</a></router-link>
                <router-link tag="li" :to="{ name: 'time-sheets' }" exact><a class="nav-link">Time Sheets</a></router-link>
                <router-link tag="li" to="/vehicles" exact><a class="nav-link">Vehicles</a></router-link>
            </ul>
            <!-- Right Nav -->
            <ul class="nav navbar-nav">
                <b-nav-item-dropdown>
                    <template slot="button-content">{{ full_name }} <i class="nav-caret hidden-sm-down zmdi zmdi-hc-sm zmdi-chevron-down"></i></template>
                    <!--<b-dropdown-item href="/settings">Settings</b-dropdown-item>-->
                    <b-dropdown-item href="#" @click.prevent="logout">Logout</b-dropdown-item>
                </b-nav-item-dropdown>
            </ul>
        </b-collapse>
    </nav>
</template>

<script>
    import store from './../store'
    import { mapState } from 'vuex'
    import { LOGOUT } from './../store/mutation-types'

    export default {
        computed: mapState({
            full_name: state => state.auth.user ? state.auth.user.full_name : ''
        }),
        methods: {
            logout() {
                store.commit(LOGOUT)
                this.$router.push('/login')
            }
        }
    }
</script>
