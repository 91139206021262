<template>
    <transition name="fade" mode="in-out">
        <div v-if="typesStatusesLoaded && loaded">
            <div class="row">
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="mb-4">Field Report for Job <span v-if="job">{{ job.cartel_job_number }}</span>
                            </h2>
                            <dl v-if="job.id" class="row">
                                <dt class="col-2">Cartel Job:</dt>
                                <dd class="col-10">{{ job.cartel_job_number }}</dd>
                                <dt class="col-2">Telstra Job:</dt>
                                <dd class="col-10">{{ job.telstra_job_number }}</dd>
                                <dt class="col-2">Address:</dt>
                                <dd class="col-10">{{ job.address.name }}</dd>
                                <dt class="col-2">Start</dt>
                                <dd class="col-10">{{ job.start }}</dd>
                                <dt v-if="job.scope_of_works != null" class="col-2">Scope of works</dt>
                                <dd v-if="job.scope_of_works != null" class="col-10">
                                    <div v-html="job.scope_of_works"></div>
                                </dd>
                            </dl>
                            <div class="form-actions">
                                <div class="form-group">
                                    <button v-if="hasUploadedFiles()" @click="downloadFieldFiles" class="btn" :class="{ 'btn-success': !downloading, 'btn-info': downloading }">
                                        <span v-if="downloading">Please Wait... </span><span v-else>Download Field Files</span>
                                    </button>
                                    <button @click="completeJob" v-if="job.status.id < statusNames.complete" class="btn btn-success">Complete Job</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <h2 class="mb-4">Workers</h2>
                            <dl v-if="job.id">
                                <div v-for="user in job.users" :key="user.id" class="row">
                                    <dt class="col-4">{{ user.full_name }}</dt>
                                    <dd class="col-8">{{ user.email }} {{ user.phone }}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-body">
<!--                            <gmap-map :center="mapCenter" :zoom="14" :options="mapOptions" style="width: 100%; height: 600px;">-->
<!--                                <gmap-marker :position="mapCenter"></gmap-marker>-->
<!--                                <template v-if="mapsLoaded" v-for="user in markers">-->
<!--                                    <gmap-marker v-for="marker in user.markers" :key="marker.id" :position="marker.position" :clickable="true" :draggable="false" @click="center=marker.position" :icon="user.icon"></gmap-marker>-->
<!--                                </template>-->
<!--                            </gmap-map>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="card images">
                <h2 class="mb-4" v-if="hasUploadedFiles()">Uploaded Photos</h2>
                <h2 v-else>No Uploaded Photos</h2>
                <transition-group tag="div" class="row">
                    <div class="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex flex-column justify-content-between" v-for="file in job.files" :key="file.id" v-if="file.type === fileTypes.uploaded">
                        <a :href="file.fileS3" target="_blank">
                            <img :src="file.thumbnailS3" :alt="file.filename" class="img-fluid mb-4">
                        </a>
                        <div v-if="file.note != null" class="mb-4" v-html="file.note"></div>
                        <hr class="">
                    </div>
                </transition-group>
            </div>
            <div class="card images">
                <h2 class="mb-4" v-if="hasUserNotes">Notes</h2>
                <h2 v-else>No Notes</h2>
                <transition-group tag="div" class="row">
                    <div class="col-sm-6 col-lg-4 mb-4" v-for="userNote in userNotes" :key="userNote.user.id" v-if="userNote != null">
                        <div v-for="note in userNote.notes" :key="note.id" class="mb-2">
                            <h5>{{ userNote.user.first_name }} {{ userNote.user.last_name }} - {{ note.created_at | dateTimeFormatted }}</h5>
                            <div>{{ note.note }}</div>
                            <hr>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
    </transition>
</template>

<script>
  import api from './../../api'
  import { mapState } from 'vuex'
  import { JOBADD, JOBUPDATE } from '../../store/mutation-types'
  import store from '../../store'

  // TODO fix the map, there is a problem with vue2-google-maps and vite, rollback this file to before winston's commit (56b6f108) to see
  // import * as VueGoogleMaps from 'vue2-google-maps'
  // import { gmapApi } from 'vue2-google-maps'
  // import Vue from 'vue'

  // Vue.use(VueGoogleMaps, {
  //   load: {
  //     key: 'AIzaSyA88N9hUfWt9DNgOK_bASz3A6Sczdz0Uig',
  //   }
  // })

  export default {
    props: {
      id: {
        required: false,
        default: null
      }
    },
    data () {
      return {
        note: {
          job_id: this.id,
          note: ''
        },
        mapsLoaded: false,
        loaded: false,
        mapOptions: {
          styles: [{ 'elementType': 'geometry', 'stylers': [{ 'color': '#f5f5f5' }] }, {
            'elementType': 'labels.icon',
            'stylers': [{ 'visibility': 'off' }]
          }, {
            'elementType': 'labels.text.fill',
            'stylers': [{ 'color': '#616161' }]
          }, {
            'elementType': 'labels.text.stroke',
            'stylers': [{ 'color': '#f5f5f5' }]
          }, {
            'featureType': 'administrative.land_parcel',
            'elementType': 'labels.text.fill',
            'stylers': [{ 'color': '#bdbdbd' }]
          }, {
            'featureType': 'poi',
            'elementType': 'geometry',
            'stylers': [{ 'color': '#eeeeee' }, { 'lightness': -15 }]
          }, {
            'featureType': 'poi',
            'elementType': 'labels.text.fill',
            'stylers': [{ 'color': '#757575' }]
          }, {
            'featureType': 'poi.park',
            'elementType': 'geometry',
            'stylers': [{ 'color': '#e5e5e5' }]
          }, {
            'featureType': 'poi.park',
            'elementType': 'labels.text.fill',
            'stylers': [{ 'color': '#9e9e9e' }]
          }, {
            'featureType': 'road',
            'elementType': 'geometry',
            'stylers': [{ 'color': '#ffffff' }]
          }, {
            'featureType': 'road.arterial',
            'elementType': 'geometry',
            'stylers': [{ 'color': '#6470ae' }, { 'lightness': 80 }]
          }, {
            'featureType': 'road.arterial',
            'elementType': 'labels.text.fill',
            'stylers': [{ 'color': '#757575' }]
          }, {
            'featureType': 'road.highway',
            'elementType': 'geometry',
            'stylers': [{ 'color': '#6470ae' }, { 'lightness': 80 }]
          }, {
            'featureType': 'road.highway',
            'elementType': 'geometry.stroke',
            'stylers': [{ 'color': '#283885' }, { 'weight': 0.5 }]
          }, {
            'featureType': 'road.highway',
            'elementType': 'labels.text.fill',
            'stylers': [{ 'color': '#616161' }]
          }, {
            'featureType': 'road.local',
            'elementType': 'geometry',
            'stylers': [{ 'color': '#6470ae' }, { 'lightness': 80 }]
          }, {
            'featureType': 'road.local',
            'elementType': 'labels.text.fill',
            'stylers': [{ 'color': '#9e9e9e' }]
          }, {
            'featureType': 'transit.line',
            'elementType': 'geometry',
            'stylers': [{ 'color': '#e5e5e5' }]
          }, {
            'featureType': 'transit.station',
            'elementType': 'geometry',
            'stylers': [{ 'color': '#eeeeee' }]
          }, {
            'featureType': 'water',
            'elementType': 'geometry',
            'stylers': [{ 'color': '#283885' }, { 'lightness': 70 }]
          }, { 'featureType': 'water', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#9e9e9e' }] }]
        },
        downloading: false
      }
    },
    computed: {
      mapCenter () {
        return {
          lat: this.job.lat,
          lng: this.job.lng
        }
      },
      // TODO fix the map
      // google: gmapApi,
      // google: null,
      ...mapState({
        job: state => state.jobs.job,
        notes: state => state.jobs.job.notes,
        userNotes: state => {
          if(state.jobs.job?.users.length > 0) {
            const notes = _.flatMap(state.jobs.job.users, function (user) {
              return {
                user: _.pick(user, ['id', 'username', 'first_name', 'last_name', 'email', 'phone']),
                notes: _.flatMap(user.job_users, job_user => job_user.notes)
              }
            })
            return notes
          }
          return []
        },
        fileTypes: state => state.typesStatuses.fileTypesNames,
        typesStatusesLoaded: state => state.typesStatuses.loaded,
        statusNames: state => state.typesStatuses.jobStatusNames,
        markers (state) {
          const markers = []
          const colours = [
            '#440000',
            '#004400',
            '#000044',
            '#880000',
            '#008800',
            '#000088',
          ]
          _.each(state.jobs.job.users, (user, i) => {
            let prevlat = 0
            let prevlng = 0
            let colour = colours.pop()
            markers[i] = {
              user: user,
              icon: {
                path: this.google?.maps.SymbolPath.CIRCLE,
                scale: 3,
                fillColor: colour,
                fillOpacity: 1,
                strokeColor: colour,
                strokeOpacity: 1,
                strokeWeight: 2
              },
              markers: []
            }
            _.each(user.job_logs, function (log, j) {
              var dlat = Math.abs(log.lat - prevlat)
              var dlng = Math.abs(log.lng - prevlng)
              if (dlat > 0.001 && dlng > 0.001 && log.lat != null && log.lng != null) {
                markers[i].markers.push({
                  id: log.id,
                  user_id: log.user_id,
                  position: {
                    lat: log.lat,
                    lng: log.lng
                  },
                  created_at: log.created_at,
                  type: log.type
                })
              }
              prevlat = log.lat
              prevlng = log.lng
            })
          })
          return markers
        },
      }),
      hasUserNotes () {
        if (this.userNotes.length === 0) {
          return false
        }

        let hasNotes = false
        _.each(this.userNotes, (userNote) => {
          if (userNote && userNote.notes.length > 0) {
            hasNotes = true
          }
        })

        return hasNotes
      }
    },
    methods: {
      completeJob () {
        swal({
          title: 'Are you sure you want to complete this Job?',
          text: 'By completing this job you confirm that all the work is complete.',
          icon: 'warning',
          buttons: {
            confirm: {
              text: 'Complete Job',
              value: true,
              className: 'btn-success'
            },
            cancel: {
              text: 'Cancel',
              value: false,
              className: 'btn-danger',
              visible: true
            }
          }
        }).then((isConfirm) => {
          if (isConfirm) {
            api.jobComplete(this.job.id).then(() => {
              this.$router.push('/jobs')
            })
          }
        })
      },
      downloadFieldFiles () {
        this.downloading = true
        api.jobsFieldFiles(this.id).then((link) => {
          this.downloading = false
        }).catch((err) => {
          console.log('download field files failed', err)
          this.downloading = false
        })
      },
      hasUploadedFiles () {
        if (this.job.files.length === 0) {
          return false
        }

        let hasFiles = false
        _.each(this.job.files, (file) => {
          if (file.type === this.fileTypes.uploaded) {
            hasFiles = true
          }
        })

        return hasFiles
      }
    },
    created () {
      if (this.id !== null) {
        let that = this
        api.jobGet(this.id).then(async response => {
          store.commit(JOBADD, response.data.job)
          let userLogs = await api.jobGetUserLogs(that.id)
          store.commit(JOBUPDATE, {key: 'users', value: userLogs})
          let files = await api.jobGetFiles(that.id)
          store.commit(JOBUPDATE, {key: 'files', value: files})
          that.loaded = true
        })

        Echo.private('job.' + this.id)
          .listen('JobUpdated', (e) => {
            console.log('JobUpdated', e)
            api.jobGet(this.id).then(function (response) {
              store.commit(JOBADD, response.data.job)
            })
          })
      }
      // TODO fix the map
      // this.$gmapApiPromiseLazy({}).then(() => {
      //   this.mapsLoaded = true
      // })
    },
    destroyed () {
      Echo.leave('Cartel.Job.' + this.id)
    }
  }
</script>
