<template>
    <div class="search-filter mb-3 form-inline">
        <div class="form-group mr-3">
            <label class="mr-2">Project Manager:</label>
            <select class="form-control" v-model="currentProjectManager" @change="doFilter">
                <option :value="null">Show All</option>
                <option v-if="projectManagers.length > 0" v-for="projectManager in projectManagers" :value="projectManager.id">{{ projectManager.full_name }}</option>
            </select>
        </div>
        <div class="form-group mr-3">
            <label class="mr-2">Job Status:</label>
            <select class="form-control" v-model="currentStatus" @change="doFilter">
                <option :value="null">Show All</option>
                <option value="0|1|2|3">Pending, Assigned, Started, On hold</option>
                <option value="1|2|3">Assigned, Started, On hold</option>
                <optgroup label="Single statuses">
                <option v-if="statuses.length > 0" v-for="status in statuses" :value="status.id">{{ status.name }}</option>
                </optgroup>
            </select>
        </div>
        <div class="form-group w-50">
            <label class="mr-2">Search:</label>
            <input v-model="search" class="form-control mr-1 w-50" @keyup.enter="doSearch" :placeholder="placeholder">
            <button class="btn btn-primary mr-1" @click="doSearch">Go</button>
            <button class="btn btn-secondary" @click="resetSearch">Reset</button>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'

    export default {
        props: {
            placeholder: {
                type: String,
                default: 'Search by address, Cartel job number or Telstra job number'
            },
            filters: {
                type: Object
            }
        },
        computed: mapState({
            statusNames: state => state.typesStatuses.jobStatusNames,
            statuses: state => state.typesStatuses.jobStatus,
            projectManagers: state => state.users.projectManagers
        }),
        data () {
            return {
                currentStatus: null,
	            currentProjectManager: null,
                search: null,
            }
        },
        methods: {
            doFilter() {
                this.$emit('filter-set', { status: this.currentStatus, project_manager: this.currentProjectManager })
            },
            doSearch() {
                this.$emit('search-set', this.search)
            },
            resetSearch() {
                this.searchText = ''
                this.$emit('search-reset', this.search)
            }
        },
        watch: {
        	projectManagers(newPMs, oldPMs) {
        		// Set project manager only once it is loaded
                if(oldPMs == null) {
	                this.currentProjectManager = this.filters.project_manager
                }
	        },
        	statuses(newStatuses, oldStatuses) {
        		// Set status only once it is loaded
                if(oldStatuses == null) {
	                this.currentStatus = this.filters.status
                }
	        },
            filters: {
        	  deep:true,
              immediate: true,
              handler() {
                this.currentStatus = this.filters.status
                this.currentProjectManager = this.filters.project_manager
                this.search = this.filters.search
              }
        	}
        },
        mounted () {
        }
    }
</script>
