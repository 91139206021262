<template>
    <div v-if="tablePagination != null" class="small mb-1" :class="['vuetable-pagination-info', css.infoClass]" v-html="paginationInfo"></div>
</template>

<script>
    import VuetablePaginationInfoMixin from 'vuetable-2/src/components/VuetablePaginationInfoMixin.vue'

    export default {
        mixins: [VuetablePaginationInfoMixin],
    }
</script>