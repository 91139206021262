// Auth
export const SETTOKEN = 'SETTOKEN'
export const SETUSER = 'SETUSER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

// Jobs
export const JOBSADD = 'JOBSADD'
export const JOBADD = 'JOBADD'
export const FILEUPDATE = 'FILEUPDATE'
export const JOBUPDATE = 'JOBUPDATE'
export const JOBDELETE = 'JOBDELETE'
export const JOBNOTEADD = 'JOBNOTEADD'
export const JOBNOTEDELETE = 'JOBNOTEDELETE'
export const JOBFILEADD = 'JOBFILEADD'
export const JOBFILEUPDATE = 'JOBFILEUPDATE'
export const JOBFILEDELETE = 'JOBFILEDELETE'
export const JOBSETSTATUSFILTER = 'JOBSETSTATUSFILTER'
export const JOBSETPROJECTMANAGERFILTER = 'JOBSETPROJECTMANAGERFILTER'

// Loader
export const LOADERINCREMENT = 'LOADERINCREMENT'
export const LOADERDECREMENT = 'LOADERDECREMENT'
export const LOADERSETMESSAGE = 'LOADERSETMESSAGE'
export const LOADERSHOWERROR = 'LOADERSHOWERROR'

// Users
export const USERSADD = 'USERSADD'
export const PMUSERSADD = 'PMUSERSADD'
export const USERADD = 'USERADD'
export const USERUPDATE = 'USERUPDATE'
export const USERDELETE = 'USERDELETE'
export const USERSUPDATE = 'USERSUPDATE'

export const WEEKCURRENT = 'WEEKCURRENT'
export const WEEKS = 'WEEKS'
export const TIMESHEETUSERS = 'TIMESHEETUSERS'

// Vehicles
export const VEHICLESADD = 'VEHICLESADD'
export const VEHICLEADD = 'VEHICLEADD'
export const VEHICLEUPDATE = 'VEHICLEUPDATE'
export const VEHICLEDELETE = 'VEHICLEDELETE'

// TypesStatuses
export const TYPESTATUSSADD = 'TYPESTATUSSADD'

