<template>
    <div class="form-group">
        <label v-if="label" :for="id">{{ label }}</label>
        <input
                :value="value"
                @input="updateValue"
                :id="id"
                type="text"
                class="form-control"
                :placeholder="placeholder"
                :name="id"
                :disabled="disabled"
        >
    </div>
</template>

<script>
	export default {
        props: {
            id: {},
            label: {
                default: null
            },
            value: {},
            placeholder: {},
            disabled: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            updateValue: function (e) {
                this.$emit('input', e.target.value)
            }
        }
	}
</script>