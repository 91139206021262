import Vue from 'vue';
import Vuex from 'vuex';
import { createLogger } from 'vuex'
// import * as actions from './actions';
// import * as getters from './getters';

// Modules
import auth from './modules/auth';
import jobs from './modules/jobs';
import loader from './modules/loader';
import typesStatuses from './modules/types-statuses';
import users from './modules/users';
import vehicles from './modules/vehicles';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    // actions,
    // getters,
    modules: {
        auth,
        jobs,
        loader,
        typesStatuses,
        users,
        vehicles
    },

    strict: debug,
    plugins: debug ? [createLogger()] : [],
});
