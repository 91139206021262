<template>
    <div class="card">
        <table v-if="users != null && typesStatusesLoaded" class="table">
            <thead>
            <tr>
                <th>Username</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Type</th>
                <!--<th>Status</th>-->
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="users.length > 0" v-for="user in users">
                <td>{{ user.username }}</td>
                <td>{{ user.full_name }}</td>
                <td><a :href="mailTo(user)">{{ user.email }}</a></td>
                <td>{{ user.phone }}</td>
                <td>{{ userTypes[user.type].name }}</td>
                <!--<td class="table-select">-->
                <!--<div v-if="statuses.length">-->
                <!--<select class="form-control form-control-sm status-select" v-bind:value="user.status" @change="statusChange($event, user)">-->
                <!--<option v-for="status in statuses" v-bind:value="status.id">{{ status.name }}</option>-->
                <!--</select>-->
                <!--</div>-->
                <!--</td>-->
                <td class="table-actions actions-lg">
                    <div>
                        <button class="btn btn-zmdi-icon" @click="edit(user)">
                            <i class="zmdi mr-1 zmdi-edit text-success"></i>
                        </button>
                        <button class="btn btn-zmdi-icon" @click="deleteUser(user)" v-if="isAllowedUser && user.deleted_at === null">
                            <i class="zmdi mr-1 zmdi-delete text-danger"></i>
                        </button>
                        <button v-if="isSuperUser && user.deleted_at !== null" class="btn btn-zmdi-icon" @click="restoreUser(user)" title="Restore User">
                            <i class="zmdi mr-1 zmdi-refresh-alt text-primary"></i>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
  import api from '../../api'
  import {
    mapState
  } from 'vuex'
  import store from '../../store'
  import {
    USERADD, USERSUPDATE
  } from '../../store/mutation-types'
  import PSText from '../../modules/form/PSText.vue'
  import PSTypeAhead from '../../modules/form/PSTypeAhead.vue'

  export default {
    data () {
      return {}
    },
    computed: {
      ...mapState({
        users: state => state.users.users,
        userTypes: state => state.typesStatuses.userTypes,
        statuses: state => state.typesStatuses.status,
        typesStatusesLoaded: state => state.typesStatuses.loaded,
        isSuperUser: state => state.auth.user.type === state.typesStatuses.userTypesNames.administrator,
        isAllowedUser: state => state.auth.user.type === state.typesStatuses.userTypesNames.administrator || state.auth.user.type === state.typesStatuses.userTypesNames.office
      })
    },
    methods: {
      // statusChange(value, user) {
      //     console.log(value, user)
      // },
      edit (user) {
        store.commit(USERADD, user)
        this.$router.push({ name: 'users-edit', params: { id: user.id } })
      },
      deleteUser (user) {
        swal({
          title: 'Are you sure you want to delete this User?',
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Delete User',
              value: true,
              className: 'btn-danger'
            },
            cancel: {
              text: 'Cancel',
              value: false,
              className: 'btn-success',
              visible: true
            }
          }
        }).then((isConfirm) => {
          if (isConfirm) {
            api.usersDelete(user).then((response) => {
              console.log(response)
              this.$store.commit(USERSUPDATE, response.data)
            })
          }
        })
      },
      mailTo (user) {
        return 'mailto:' + user.email
      },
      restoreUser (user) {
        swal({
          title: 'Are you sure you want to restore this User?',
          buttons: {
            confirm: {
              text: 'Restore User',
              value: true,
              className: 'btn-success'
            },
            cancel: {
              text: 'Cancel',
              value: false,
              className: 'btn-danger',
              visible: true
            }
          }
        }).then((isConfirm) => {
          if (isConfirm) {
            api.usersRestore(user).then((response) => {
              console.log(response)
              this.$store.commit(USERSUPDATE, response.data)
            })
          }
        })
      },
    },
    mounted () {
      api.usersGet()
    },
    watch: {
      search_text: function (val) {
        this.status = []
        this.textSearch()
      }
    },
    components: {
      PSText,
      PSTypeAhead
    },
  }
</script>
