<template>
    <div id="main" class="container-fluid">
        <h1>Settings</h1>
    </div>
</template>

<script>
    export default {
        mounted() {
        }
    }
</script>
